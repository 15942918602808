import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AutomationPageInitialState,} from './types';
import { getAutomationConfig, getLeadsToExclude, runAutomation, updateConfig } from './thunks';
import { LeadInformation } from '../../../../types/entities/leadInformation';

const initialState: AutomationPageInitialState = {
  currentNumberOfReplyForToday: 0,
  delayBetweenComments: [0, 5],
  delayAutomation: [0, 5],
  scrollStep: 0,
  getAutomationDataloading: false,
  selectedTimeZone: '',
  selectedDays: '',
  workingHours: [6, 18],
  dailyCommentsQouta: 10,
  isUpdating: false,
  leadsList: [],
  excludeLeadsList: [],
  limitOfReplyForADay: 0,
  useStaticPrompt: false,
  //run automation
  automationTab: null,
  isRunAutomationRequest: false,
  isAutomationRunning: false,
  //get leads
  isGetLeadsToExcludeRequest: false,
  currentPage: 0,
  hasMore: true,
  isShowOnlyExcluded: false,
  inputName: '',
};

export const automationSlice = createSlice({
  name: 'automationSlice',
  initialState,
  reducers: {
    setCurrentNumberOfReplyForToday: (state, { payload }: PayloadAction<number>) => {
      state.currentNumberOfReplyForToday = payload;
    },
    setLimitOfReplyForADay: (state, { payload }: PayloadAction<number>) => {
      state.limitOfReplyForADay = payload;
    },
    setDelayBetweenComments: (state, { payload }: PayloadAction<number[]>) => {
      state.delayBetweenComments = payload;
    },
    setDelayAutomation: (state, { payload }: PayloadAction<number[]>) => {
      state.delayAutomation = payload;
    },
    setSelectedTimeZone: (state, { payload }: PayloadAction<string>) => {
      state.selectedTimeZone = payload;
    },
    setSelectedDays: (state, { payload }: PayloadAction<string>) => {
      state.selectedDays = payload;
    },
    setWorkingHours: (state, { payload }: PayloadAction<number[]>) => {
      state.workingHours = payload;
    },
    setDailyCommentsQouta: (state, { payload }: PayloadAction<number>) => {
      state.dailyCommentsQouta = payload;
    },
    toogleLeadToExcludeLeadList: (state, { payload }: PayloadAction<LeadInformation>) => {
      const currentLead = state.excludeLeadsList.find(lead => lead.leadIdentitySN === payload.leadIdentitySN)
      if(currentLead) {
        state.excludeLeadsList = state.excludeLeadsList.filter(lead => lead.leadIdentitySN !== payload.leadIdentitySN)
        return;
      }
      state.excludeLeadsList.push(payload);
    },
    setShowOnlyExcluded: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowOnlyExcluded = payload;
    },
    setInputName: (state, { payload }: PayloadAction<string>) => {
      state.inputName = payload;
    },        
  },
  extraReducers: (builder) => {
    //Get automation config
    builder.addCase(getAutomationConfig.pending, (state) => {
      state.getAutomationDataloading = true;
    })
    builder.addCase(getAutomationConfig.fulfilled, (state, action) => {
      state.delayBetweenComments = [action.payload.minDelayBetweenReply, action.payload.maxDelayBetweenReply]
      state.delayAutomation = [action.payload.minDelayInHour, action.payload.maxDelayInHour]
      state.currentNumberOfReplyForToday = action.payload.currentNumberOfReplyForToday;
      state.isAutomationRunning = action.payload.isAutomationRunning;
      state.automationTab = action.payload.tab;
      state.getAutomationDataloading = false;
      state.limitOfReplyForADay = action.payload.limitOfReplyForADay
      state.dailyCommentsQouta = action.payload.limitOfReplyForADay
      state.useStaticPrompt = action.payload.useStaticPrompt
    })    
    builder.addCase(getAutomationConfig.rejected, (state, action) => {
      state.getAutomationDataloading = false;
    })
    //Update config
    builder.addCase(updateConfig.pending, (state) => {
      state.isUpdating = true;
    })
    builder.addCase(updateConfig.fulfilled, (state, action) => {
      state.delayBetweenComments = [action.payload.minDelayBetweenReply, action.payload.maxDelayBetweenReply]
      state.delayAutomation = [action.payload.minDelayInHour, action.payload.maxDelayInHour]
      state.currentNumberOfReplyForToday = action.payload.currentNumberOfReplyForToday;
      state.isUpdating = false;
      state.dailyCommentsQouta = action.payload.limitOfReplyForADay
      state.limitOfReplyForADay = action.payload.limitOfReplyForADay
    })
    builder.addCase(updateConfig.rejected, (state, action) => {
      state.isUpdating = false;
    })
    //runAutomation
    builder.addCase(runAutomation.pending, (state, action) => {
      state.isRunAutomationRequest = true;
    })
    builder.addCase(runAutomation.fulfilled, (state, action) => {
      if(action.payload.isAutomationRunning) {
        state.automationTab = action.meta.arg.tab;
        state.isAutomationRunning = true;
        state.isRunAutomationRequest = false;
        return;
      }

      state.automationTab = null;
      state.isAutomationRunning = false;
      state.isRunAutomationRequest = false;
    })
    builder.addCase(runAutomation.rejected, (state, action) => {
      state.isRunAutomationRequest = false;
    })
    //get leads to exclude
    builder.addCase(getLeadsToExclude.pending, (state, action) => {
      state.isGetLeadsToExcludeRequest = true;
    })
    builder.addCase(getLeadsToExclude.fulfilled, (state, action) => {
      const name = action.meta.arg.name;
      if (name) {
        state.leadsList = action.payload.leads;
        state.currentPage = 0;
      } else {
        if(state.currentPage === 0) {
          state.leadsList = [];
        }
        state.leadsList.push(...action.payload.leads);
        state.currentPage = state.currentPage + 1;
      }
      state.excludeLeadsList.push(...action.payload.alreadyExcludedLeads)
      state.hasMore = !(action.payload.leads.length < 10)
      state.isGetLeadsToExcludeRequest = false;
    })
    builder.addCase(getLeadsToExclude.rejected, (state, action) => {
      state.isGetLeadsToExcludeRequest = false;
    })
  }
});

export const automationActions = automationSlice.actions;
export const automationReducer = automationSlice.reducer;