import { Box } from '@mui/material';
import * as React from 'react';
import style from '../../botManagementPage.module.scss'


export const SnLinksHeader = () => {
  return (
    <Box className={style.botManagement__botsStatisticHeader}>
      <Box
        sx={{ width: 20 }}
        className={style.botManagement__headerStatisticBlock}
      >
        id
      </Box>
      <Box
        sx={{ width: 200 }}
        className={style.botManagement__headerStatisticBlock}
      >
        Date
      </Box>
      <Box
        className={style.botManagement__headerStatisticBlock}
        sx={{ width: 150 }}
      >
        Uploading step
      </Box>
      <Box
        className={style.botManagement__headerStatisticBlock}
        sx={{ width: 50 }}
      >
        Leads count
      </Box>
      <Box
        className={style.botManagement__headerStatisticBlock}
        sx={{ width: 300 }}
      >
        Uploading error message
      </Box>

    </Box>
  )
}

