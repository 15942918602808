import React from 'react';
import styles from "./AddBot.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { Avatar, Box, CircularProgress, Typography } from '@mui/material';
import { useBotManagementActions } from '../../../utils/redux/storeHooks/actionsHooks/botManagementActions';
import { useBotManagementData } from '../../../utils/redux/storeHooks/dataHooks/useBotManagementData';
import AddIcon from '@mui/icons-material/Add';
import { Bot } from '../../../types/pages/botManagmentPage/bot';

export const PopUpAddBot = () => {
  const { users, userUuidForAddBot } = useBotManagementData();
  const { addUserLikeBot } = useBotManagementActions();
  const { setPopUpAddBotOpen } = usePopupActions();

  const closePopUp = () => {
    setPopUpAddBotOpen(false)
  }

  const addBot = (user: Bot) => {
    addUserLikeBot({ user })
  }

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.header__title}>
          Add user to bot`s list
        </Box>
        <IconButton
          color="default"
          onClick={closePopUp}
          className={styles.header__closeButton}
        >
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>
      <Box className={styles.content}>
        {users.map(user => {
          const isUserUpdated = userUuidForAddBot === user.uidd
          return (
            <Box key={user.uidd} className={styles.content__item}>
              <Avatar
                alt=""
                src={user.profileImg}
                sx={{ width: '48px', height: '48px' }}
              />
              <Typography className={styles.content__name}>
                {`${user.firstName} ${user.lastName}`}
              </Typography>
              <Button
                className={styles.content__addBtn}
                onClick={() => addBot(user)}
              >
                {isUserUpdated
                  ? <CircularProgress sx={{ color: '#3B2470' }} size={28} />
                  : <AddIcon sx={{ color: '#3B2470' }} />
                }
              </Button>
            </Box>
          )
        })}
      </Box>

      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={closePopUp}
          className={styles.footer__cancel}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};
