import { useAppSelector } from '../typedHooks';

export const useTableLeadsData = () => {
  const {
    rows,
    orderBy,
    order,
    selected,
    page,
    rowsPerPage,
    leadToSearch,
    loading,
    isSlotsLeft,
    statusOfSend,
    errorOfSend,
    selectedRowForMenu,
    isDeletingOrUpdateProcess,
    responceText,
    teamList,
    isTeamListLoading,
    isLoadingSnLinkRequest,
    uploadingSnLink,
    isSendingNotificationError,
    isStopLoadSnLinkRequestLoading,
    allLeadsCount,
    dateInserted,
    isFilterOpen,
    dateLastCommented,
    dateLastReplied,
    selectedLeadConnectionPossition,
    filterCompanyName,
    filterLeadName,
    filtersSelectedCount,
    isFilterApply,
    appliedFilersCount,
    isExportingLeads,
    isNeedToReloadLeadsByApplyingFilter,
    isHaveNewActivitySelect,
    isAllSelected
  } = useAppSelector((state) => state.tableLeadsReducer);

  return {
    rows,
    orderBy,
    order,
    selected,
    page,
    rowsPerPage,
    leadToSearch,
    loading,
    isSlotsLeft,
    statusOfSend,
    errorOfSend,
    selectedRowForMenu,
    isDeletingOrUpdateProcess,
    responceText,
    teamList,
    isTeamListLoading,
    isLoadingSnLinkRequest,
    uploadingSnLink,
    isSendingNotificationError,
    isStopLoadSnLinkRequestLoading,
    allLeadsCount,
    dateInserted,
    isFilterOpen,
    dateLastCommented,
    dateLastReplied,
    selectedLeadConnectionPossition,
    filterCompanyName,
    filterLeadName,
    filtersSelectedCount,
    isFilterApply,
    appliedFilersCount,
    isExportingLeads,
    isNeedToReloadLeadsByApplyingFilter,
    isHaveNewActivitySelect,
    isAllSelected
  };
};