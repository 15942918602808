import React, { useState } from 'react';
import styles from "./SendExtension.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { Box, Typography } from '@mui/material';
import { MainActionButton } from '../../actionButton/MainActionButton';
import { StyledTextField } from '../../styledTextField/StyledTextField';
import { useAdminPageActions } from '../../../utils/redux/storeHooks/actionsHooks/useAdminPageActions';
import { useAdminPageData } from '../../../utils/redux/storeHooks/dataHooks/useAdminPageData';

export const PopUpSendExtension = () => {
  const [inputValue, setInputValue] = useState('')
  const [inputLinkValue, setInputLinkValue] = useState('')
  const [isValueCorrect, setIsValueCorrect] = useState(true)
  const { setPopupSendExtensionOpen } = usePopupActions();
  const { sendEmailExtension } = useAdminPageActions();
  const { isSendingExtension } = useAdminPageData()

  const closePopUp = () => {
    setPopupSendExtensionOpen(false)
  }

  const sendExtension = () => {
    if (inputValue && isValidEmail(inputValue)) {
      sendEmailExtension({ email: inputValue, link: inputLinkValue })
    }
  }

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const onInputChange = (value: string) => {
    setInputValue(value)
    if (value && !isValidEmail(value)) {
      setIsValueCorrect(false)
    } else {
      setIsValueCorrect(true)
    }
  }

  const onInputLinkChange = (value: string) => {
    setInputLinkValue(value)
  }

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.header__title}>
          Send extension
        </Box>
        <IconButton
          color="default"
          onClick={closePopUp}
          className={styles.header__closeButton}
        >
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>
      <Box className={styles.content}>
        <Typography className={styles.content__text}>
          Send extension for new user, please paste email and link below:
        </Typography>
        <Box className={styles.content__settings}>
          <Box className={styles.content__setting}>
            <Typography className={styles.content__settingTitle}>
              email:
            </Typography>
            <StyledTextField
              error={!isValueCorrect}
              sx={{ width: 400 }}
              value={inputValue}
              onChange={(e) => onInputChange(e.target.value)}
            />
            {!isValueCorrect && <Typography className={styles.content__textInvalid}>
              Inccorect email
            </Typography>}
          </Box>
          <Box className={styles.content__setting}>
            <Typography className={styles.content__settingTitle}>
              link:
            </Typography>
            <StyledTextField
              sx={{ width: 400 }}
              value={inputLinkValue}
              onChange={(e) => onInputLinkChange(e.target.value)}
            />
          </Box>
        </Box>
      </Box>

      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={closePopUp}
          className={styles.footer__cancel}
        >
          Cancel
        </Button>
        <MainActionButton
          title='Send'
          height={40}
          width={80}
          fontWeight={300}
          isLoading={isSendingExtension}
          isDisabled={isSendingExtension || !isValueCorrect || !inputValue || !inputLinkValue}
          actionOnClick={sendExtension}
        />
      </Box>
    </Box>
  );
};
