import * as React from 'react';
import styles from '../TableImportCSV.module.scss';
import { Box, CircularProgress, IconButton, Menu, MenuItem, TableBody, TableCell, TableRow } from '@mui/material';
import { TableLoader } from '../../tableLoader/TableLoader';
import { useTableImportCSVData } from '../../../utils/redux/storeHooks/dataHooks/useTableImportCSVData';
import { useTableImportCSVActions } from '../../../utils/redux/storeHooks/actionsHooks/useTableImportCSVActions';
import { useState } from 'react';
import { TableImportCSVData } from '../../../types/pages/dashboardLeadsPeoplePage/tableImportCSV/tableImportCSVData';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import moment from 'moment';
import { Statuses } from '../../../types/components/uploadingCSVIndicator/statuses';
import { RedCheckbox } from '../../../utils/pages/dashboardLeadsPeoplePage/redCheckbox';
import { actionMenu } from '../../../utils/pages/dashboardLeadsPeoplePage/tableImportCSV/actionMenu';

export const TableImportCSVBody = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const {
    rows,
    loading,
    selectedImports,
    isDeletingProcess,
    selectedRowForMenu
  } = useTableImportCSVData()
  const {
    deleteCSVAndUpdateList,
    toggleSelectedImports,
    setSelectedRowForMenu
  } = useTableImportCSVActions()

  const handleSelectLead = (id: string) => {
    toggleSelectedImports(id)
  };

  const isSelected = (id: string) => selectedImports.indexOf(id) !== -1;

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>, row: TableImportCSVData) => {
    setSelectedRowForMenu(row);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedRowForMenu(null)
  };

  const onDeleteImport = async () => {
    setAnchorEl(null);
    deleteCSVAndUpdateList({ selectedImports: [selectedRowForMenu.documentUuid] })
  };

  return (
    <TableBody className={styles.tableBody} sx={{ overflow: 'hidden' }}>
      {loading && rows.length > 0 && <TableLoader />}
      {rows.map((row) => {
        const isItemSelected = isSelected(row.documentUuid);
        return (
          <TableRow
            hover
            key={row.documentUuid}
            className={styles.tableBody__row}
            sx={{ backgroundColor: isItemSelected ? '#E0E8F9' : '' }}
          >
            <TableCell className={styles.tableBody__cell} sx={{ width: '50px' }}>
              <Box className={styles.tableBody__cellContainer}>
                <RedCheckbox
                  onClick={() => handleSelectLead(row.documentUuid)}
                  checked={isItemSelected}
                />
              </Box>
            </TableCell>

            <TableCell className={styles.tableBody__cell}>
              <Box className={styles.tableBody__cellContainer}>
                {row.fileName}
              </Box>
            </TableCell>

            <TableCell className={styles.tableBody__cell}>
              <Box className={styles.tableBody__cellContainer_date}>
                <Box className={styles.tableBody__date}>
                  {moment(row.uploadDate).format('MMM DD YYYY')}
                </Box>
                <Box className={styles.tableBody__date_name}>
                  {row.nameOfuser}

                </Box>

              </Box>
            </TableCell>

            <TableCell className={styles.tableBody__cell}>
              <Box className={styles.tableBody__cellContainer}>
                {row.countOfLeads}
              </Box>
            </TableCell>

            <TableCell className={styles.tableBody__cell}>
              <Box
                className={styles.tableBody__cellContainer}
                sx={{ color: row.status === Statuses.Uploaded ? '#6d30b7' : row.status === Statuses.Parsing ? '#25660E' : '#191F2E' }}>
                {row.status === Statuses.Uploaded && 'uploading'}
                {row.status === Statuses.Parsing && 'parcing'}
                {row.status === Statuses.Completed && 'completed'}
              </Box>
            </TableCell>

            <TableCell className={styles.tableBody__cell} align='right'>
              <Box className={styles.tableBody__cellContainer} style={{ justifyContent: 'flex-end' }}>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-haspopup="true"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleOpenMenu(event, row);
                  }}
                  disabled={isDeletingProcess}
                  sx={{
                    display: isDeletingProcess
                      && (selectedRowForMenu?.documentUuid === row.documentUuid || selectedImports.includes(row.documentUuid))
                      ? 'none'
                      : 'block'
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
                {isDeletingProcess
                  && (selectedRowForMenu?.documentUuid === row.documentUuid || selectedImports.includes(row.documentUuid))
                  && (
                    <Box sx={{
                      height: 40,
                      width: 40,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                      <CircularProgress
                        size={26}
                        color='inherit'
                      />
                    </Box>
                  )}
              </Box>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                  disablePadding: true,
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
                PaperProps={{
                  style: {
                    position: 'absolute',
                    boxShadow: '4px 4px 8px 0px rgba(0, 0, 0, 0.15)',
                    width: '150px',
                    height: '40px',
                  },
                }}
              >
                {actionMenu.map((option) => {
                  const handleClickItem = () => {
                    switch (option) {
                      case 'Remove':
                        onDeleteImport()
                        return;
                    }
                  };
                  return (
                    <MenuItem
                      key={option}
                      sx={{
                        padding: '4px',
                        height: '40px',
                      }}
                      onClick={handleClickItem}
                    >
                      <ListItemIcon>
                        <DeleteOutlineRoundedIcon sx={{
                          height: '40px',
                          color: '#191F2E'
                        }} />
                      </ListItemIcon>
                      <ListItemText>{option}</ListItemText>
                    </MenuItem>
                  );
                })}
              </Menu>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody >
  )
}