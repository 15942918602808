import * as React from 'react';
import scss from '../automation_page.module.scss'
import { useAutomationData } from '../../../utils/redux/storeHooks/dataHooks/useAutomationData';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { StyledSwitcher } from '../../../components/popups/runAutomation/features/StyledSwitcher';
import { useAutomationActions } from '../../../utils/redux/storeHooks/actionsHooks/useAutomationActions';
import { LeadInformation } from '../../../types/entities/leadInformation';

export const AutomationPageExcludedLeads = () => {
  const [expanded, setExpanded] = useState(false);
  const { excludeLeadsList } = useAutomationData()
  const { toogleLeadToExcludeLeadList } = useAutomationActions();

  const summaryList = excludeLeadsList.slice(0, 3) || []
  const detailList = excludeLeadsList.slice(3, excludeLeadsList.length)
  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const toogleLead = (lead: LeadInformation) => {
    toogleLeadToExcludeLeadList(lead)
  }

  return (
    <Box >
      <Typography className={scss.partMainTitle}>
        Excluded leads from automation
      </Typography>
      <Accordion expanded={expanded} sx={{
        width: '100%',
        boxShadow: 'none'
      }}>
        <AccordionSummary
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ width: '100%', padding: 0 }}
        >
          <Box className={scss.accordion}>
            {summaryList.map(lead => (
              <Box
                key={lead.leadIdentitySN}
                className={scss.accordion__item}
                sx={{ backgroundColor: lead.haveNewActivity ? '#FDF3D8' : '#FFF' }}
              >
                <Box className={scss.accordion__leadInfoContainer}>
                  <Avatar
                    alt=""
                    src={lead.profileImg}
                    sx={{ width: '40px', height: '40px' }}
                  />
                  <Box className={scss.accordion__infoContainer}>
                    <Typography className={scss.accordion__name}>
                      {`${lead.firstName} ${lead.lastName}`}
                    </Typography>
                    <Typography className={scss.accordion__additionalInfo}>
                      {`${lead.title?.length > 30 ? (lead.title.slice(0, 30) + '...') : (lead.title || '')}`}
                    </Typography>
                  </Box>
                </Box>
                <StyledSwitcher
                  checked
                  onChange={() => toogleLead(lead)}
                />
              </Box>
            ))}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ width: '100%', padding: 0 }}>
          <Box className={scss.accordion}>
            {detailList.map(lead => (
              <Box
                key={lead.leadIdentitySN}
                className={scss.accordion__item}
                sx={{ backgroundColor: lead.haveNewActivity ? '#FDF3D8' : '#FFF' }}
              >
                <Box className={scss.accordion__leadInfoContainer}>
                  <Avatar
                    alt=""
                    src={lead.profileImg}
                    sx={{ width: '40px', height: '40px' }}
                  />
                  <Box className={scss.accordion__infoContainer}>
                    <Typography className={scss.accordion__name}>
                      {`${lead.firstName} ${lead.lastName}`}
                    </Typography>
                    <Typography className={scss.accordion__additionalInfo}>
                      {`${lead.title?.length > 30 ? (lead.title.slice(0, 30) + '...') : (lead.title || '')}`}
                    </Typography>
                  </Box>
                </Box>
                <StyledSwitcher
                  checked
                  onChange={() => toogleLead(lead)}
                />
              </Box>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
      {excludeLeadsList.length > 3 && <Button
        onClick={handleToggle}
        sx={{
          marginTop: '12px',
          '&:hover': {
            backgroundColor: '#fff'
          },
          fontFamily: 'Kanit',
          color: '#000000',
          fontSize: 16
        }}
      >
        {expanded ? 'Show less' : `Show all ${excludeLeadsList.length} leads`}
      </Button>}
    </Box>
  )
}

