import { useMemo } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useAppDispatch } from '../typedHooks';
import { botManagementActions } from '../../slices/botManagementSlice/botManagementSlice';
import { addUserLikeBot, checkFeedDaily, checkFeedInitial, checkValidCookiesForBot, deleteUserFromBots, getBotStatistic, getBots, getNeedAdminCheckSnlinks, getUsers, loadLeadsSnGet, stopWork } from '../../slices/botManagementSlice/thunks';

const rootActions = {
  ...botManagementActions,
  getBots,
  checkValidCookiesForBot,
  checkFeedDaily,
  getUsers,
  checkFeedInitial,
  addUserLikeBot,
  deleteUserFromBots,
  stopWork,
  loadLeadsSnGet,
  getBotStatistic,
  getNeedAdminCheckSnlinks,
};

export const useBotManagementActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch]);
};