import * as React from 'react';
import scss from '../../AdminPage.module.scss';
import { Avatar, Box, CircularProgress, IconButton, Stack, TableBody, TableCell, TableRow, Typography, Zoom } from '@mui/material';
import { useAdminPageData } from '../../../../utils/redux/storeHooks/dataHooks/useAdminPageData';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DeleteIcon from '@mui/icons-material/Delete';
import { TableLoader } from '../../../../components/tableLoader/TableLoader';
import UserInformation from '../../../../types/entities/user_information';
import { useAdminPageActions } from '../../../../utils/redux/storeHooks/actionsHooks/useAdminPageActions';
import { usePopupActions } from '../../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import BuildIcon from '@mui/icons-material/Build';
import { TooltipApp } from '../../../../components/tooltipApp/TooltipApp';

export const TableUsersBody = () => {
  const { isUsersLoading, filtredUsers, isUserRepairing } = useAdminPageData();
  const { setManageCurrentUser, deleteAdditionalWrongInfo } = useAdminPageActions();
  const { setPopupManageUserOpen, setPopupDeleteUserOpen } = usePopupActions();

  const onDeleteUser = async (user: UserInformation) => {
    setManageCurrentUser(user)
    setPopupDeleteUserOpen(true)
  };

  const onManageUser = async (user: UserInformation) => {
    setManageCurrentUser(user)
    setPopupManageUserOpen(true)
  };
  console.log(filtredUsers)
  return (
    <TableBody sx={{ position: 'relative', color: '#3B2470' }}>
      {isUsersLoading && filtredUsers.length > 0 && <TableLoader />}
      {filtredUsers.map((user) => {
        return (
          <TableRow
            hover
            key={user.id}
            sx={{
              maxHeight: '64px'
            }}
          >
            <TableCell sx={{ minWidth: 150, maxWidth: 150, padding: '12px 12px' }}>
              <Box className={scss.profileWrapper}>
                <a
                  className={scss.firstLastNameText}
                  href={`https://${user.dashEntityUrn}`}
                  target='_blank'
                  rel="noreferrer"
                >
                  <Stack direction="row">
                    <Avatar
                      alt=""
                      src={user.profileImg}
                      sx={{ width: '40px', height: '40px' }}
                    />
                  </Stack>
                </a>
                <Box className={scss.userInfo} sx={{ marginLeft: '12px' }}>
                  <Box className={scss.firstLastNameText}>
                    {user.firstName?.length + user.lastName?.length > 17
                      ? `${user.firstName} ${user.lastName}`.slice(0, 17) + '...'
                      : `${user.firstName} ${user.lastName}`}
                  </Box>
                  <Box className={scss.titleText}>
                    {user.title && user.title !== undefined && user.title?.length > 18
                      ? `${user.title.slice(0, 18)}...`
                      : user.title}
                  </Box>
                </Box>
              </Box>
            </TableCell>

            <TableCell
              sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }}
              align="center">
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {user.organizationName !== 'none' &&
                  <>
                    <Typography sx={{ fontFamily: 'Kanit', fontWeight: 600 }}>
                      {user.organizationName}
                    </Typography>
                  </>
                }
              </Box>
            </TableCell>

            <TableCell
              sx={{ minWidth: 100, maxWidth: 100, padding: '12px 12px' }}
              align="center"
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                {Array.isArray(user.roles) && user.roles.map(role => <Box key={role}>{role}</Box>)}
              </Box>

            </TableCell>

            <TableCell
              sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }}
              align="center"
            >
              {user.slots}
            </TableCell>

            <TableCell
              sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }}
              align="center"
            >
              {user.slotsLeft}
            </TableCell>

            <TableCell
              sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }}
              align="center"
            >
              {user.userleadsCount}
            </TableCell>


            <TableCell sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }} align="center">
              <div>
                <IconButton color="primary" aria-label="dm" onClick={() => onManageUser(user)}>
                  <ManageAccountsIcon sx={{ color: '#3B2470' }} />
                </IconButton>
              </div>
            </TableCell>

            <TableCell sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }} align="center">
              <div>
                <IconButton color="primary" aria-label="dm" onClick={() => onDeleteUser(user)}>
                  <DeleteIcon sx={{ color: '#6f7d9b' }} />
                </IconButton>
              </div>
            </TableCell>

            <TableCell sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }} align="center">
              <TooltipApp TransitionComponent={Zoom} placement="left-start" title={
                <>
                  <span className={scss.toolTipBody}>
                    Use it if the user cannot be deleted.
                  </span>
                </>
              }>
                <div>
                  {isUserRepairing === user.uidd ? <CircularProgress
                    size={26}
                    color='inherit'
                  /> :
                    <IconButton color="primary" aria-label="dm" onClick={() => deleteAdditionalWrongInfo({ userUuid: user.uidd })}>
                      <BuildIcon sx={{ color: '#db491f', fontSize: 18 }} />
                    </IconButton>}
                </div>
              </TooltipApp>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  )
}