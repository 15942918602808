import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import styles from "../DashboardLeadsPeoplePage.module.scss";
import CalendarPicker from "../../../components/calendarPicker/CalendarPicker";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useTableLeadsActions } from "../../../utils/redux/storeHooks/actionsHooks/useTableLeadsActions";
import { FilterType } from "../../../types/pages/dashboardLeadsPeoplePage/filterType";
import { MainActionButton } from "../../../components/actionButton/MainActionButton";
import { StyledSearchBar } from "../../../components/styledTextFieldBar/StyledSearchBar";
import { useTableLeadsData } from "../../../utils/redux/storeHooks/dataHooks/useTableLeadsData";
import { StyledAutocomplete } from "../../../components/styledAutocomplete/StyledAutocomplete";
import { StyledTextField } from "../../../components/styledTextField/StyledTextField";
import { connectionPositions } from "../../../utils/pages/dashboardLeadsPeoplePage/tableLeads/connectionPositions";
import { StyledFormControlCheckbox } from "../../../components/styledFormControlCheckbox/StyledFormControlCheckbox";

export const LeadsFilter = () => {
  const {
    setIsFilterOpen,
    setSelectedLeadConnectionPosition,
    setFilterCompanyName,
    setFilterLeadName,
    setFiltersSelectedCount,
    setIsFilterApply,
    setAppliedFilersCount,
    setDateInserted,
    setDateLastCommented,
    setDateLastReplied,
    setLeadToSearch,
    setIsNeedToReloadLeadsByApplyingFilter,
    setPage,
    setRowsPerPage,
    setOrder,
    setOrderBy,
    setIsHaveNewActivitySelect
  } = useTableLeadsActions()
  const {
    selectedLeadConnectionPossition,
    filterCompanyName,
    filterLeadName,
    dateInserted,
    dateLastCommented,
    dateLastReplied,
    filtersSelectedCount,
    isNeedToReloadLeadsByApplyingFilter,
    isHaveNewActivitySelect
  } = useTableLeadsData();

  const clearAllFilter = () => {
    onLeadNameChange('')
    onCompanyNameChange('')
    setSelectedLeadConnectionPosition(null)
    setDateInserted({ startDate: null, endDate: null })
    setDateLastCommented({ startDate: null, endDate: null })
    setDateLastReplied({ startDate: null, endDate: null })
    setIsFilterApply(false)
    setAppliedFilersCount(0)
    setIsFilterOpen(false)
    setIsNeedToReloadLeadsByApplyingFilter(!isNeedToReloadLeadsByApplyingFilter)
    setLeadToSearch('')
    setIsHaveNewActivitySelect(false)
  }

  const closeFilter = () => {
    setIsFilterOpen(false)
  }

  const onLeadNameChange = (inputValue: string) => {
    setFilterLeadName(inputValue)
  };

  const onCompanyNameChange = (inputValue: string, isClear = false,) => {
    setFilterCompanyName(inputValue)
  };

  const onConnectionPossitionChange = (value: string) => {
    setSelectedLeadConnectionPosition(value)
  }

  const onHaveNewActivitySelect = () => {
    setIsHaveNewActivitySelect(!isHaveNewActivitySelect)
  }

  const filterResults = () => {
    setIsFilterApply(true)
    setAppliedFilersCount(filtersSelectedCount)
    setIsFilterOpen(false)
    setIsNeedToReloadLeadsByApplyingFilter(!isNeedToReloadLeadsByApplyingFilter)
    setOrderBy('firstName');
    setOrder('ASC');
    setPage(0)
    setRowsPerPage(10)
    setLeadToSearch(filterLeadName)
  }

  const detectChangeFilterAndCount = () => {
    let filtersCount = 0;
    filterLeadName !== '' && filtersCount++
    filterCompanyName !== '' && filtersCount++
    selectedLeadConnectionPossition && filtersCount++
    dateInserted.endDate !== null && dateInserted.startDate !== null && filtersCount++
    dateLastCommented.endDate !== null && dateLastCommented.startDate !== null && filtersCount++
    dateLastReplied.endDate !== null && dateLastReplied.startDate !== null && filtersCount++
    isHaveNewActivitySelect && filtersCount++
    setFiltersSelectedCount(filtersCount)
  }

  useEffect(() => {
    detectChangeFilterAndCount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterLeadName,
    filterCompanyName,
    selectedLeadConnectionPossition,
    dateInserted.startDate,
    dateInserted.endDate,
    dateLastCommented.startDate,
    dateLastCommented.endDate,
    dateLastReplied.startDate,
    dateLastReplied.endDate,
    isHaveNewActivitySelect
  ])

  return (
    <Box className={styles.leadsFilter} boxShadow={4} >
      <Box>
        <Box className={styles.leadsFilter__header}>
          <Box className={styles.leadsFilter__closeBlock}>
            <IconButton
              color="default"
              onClick={closeFilter}
            >
              <CloseRoundedIcon sx={{ fill: '#6F7D9B', fontSize: '18px' }} />
            </IconButton>
            <Box className={styles.leadsFilter__counter}>
              {filtersSelectedCount ? `Filter (${filtersSelectedCount})` : 'Filter'}
            </Box>
          </Box>

          <Button
            className={styles.leadsFilter__clearAllButton}
            variant="text"
            sx={{
              height: 40,
              "&:hover": { backgroundColor: '#F2F2F2' },
              color: '#3B2470'
            }}
            onClick={clearAllFilter}
          >

            Clear all
          </Button>
        </Box>
        <Box className={styles.leadsFilter__filtersContainer}>
          <CalendarPicker title="Date added" filterType={FilterType.dateAdded} />
          <CalendarPicker title="Date last replied" filterType={FilterType.dateLastReplied} />
          <CalendarPicker title="Date last commented" filterType={FilterType.dateLastCommented} />
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}>
            <Typography sx={{
              fontFamily: 'Kanit',
              color: '#6F7D9B',
              fontSize: '14px'
            }}>
              Search by name
            </Typography>
            <StyledSearchBar
              inputValue={filterLeadName}
              setFunction={onLeadNameChange}
              //width depend on filters general width look var(--filters-width) in index css
              inputWidth={350 - 48}
              inputPlaceholder="Search by name"
              isNeedToChangeRedux
            />
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}>
            <Typography sx={{
              fontFamily: 'Kanit',
              color: '#6F7D9B',
              fontSize: '14px'
            }}>
              Search by company
            </Typography>
            <StyledSearchBar
              inputValue={filterCompanyName}
              setFunction={onCompanyNameChange}
              //width depend on filters general width look var(--filters-width) in index css
              inputWidth={350 - 48}
              inputPlaceholder="Search by company"
              isNeedToChangeRedux
            />
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}>
            <Typography sx={{
              fontFamily: 'Kanit',
              color: '#6F7D9B',
              fontSize: '14px'
            }}>
              Connection
            </Typography>
            <StyledAutocomplete
              clearIcon={null}
              disablePortal
              options={connectionPositions}
              sx={{ width: '302px' }}
              value={selectedLeadConnectionPossition}
              onChange={(event, newValue: string | null) => onConnectionPossitionChange(newValue)}
              ListboxProps={{ sx: { "& li": { fontFamily: "Kanit", fontWeight: 300 } } }}
              renderInput={(params) => <StyledTextField
                value={selectedLeadConnectionPossition}
                placeholder='Connection'
                {...params}
                InputProps={{
                  ...params.InputProps,
                }}

              />}
            />
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            padding: '12px',
            // border: '1px solid red'
          }}>
            <StyledFormControlCheckbox
              isChecked={isHaveNewActivitySelect}
              onChange={onHaveNewActivitySelect}
              checkBoxName='pending replies'
              label='Show pending replies'
              labelFontColor="#6F7D9B"
              labelFontWeight={400}
            />
          </Box>
        </Box>
      </Box>

      <Box className={styles.leadsFilter__footer}>
        <MainActionButton
          title="Filter results"
          width={120}
          actionOnClick={filterResults}
        />
        <Button
          onClick={closeFilter}
          className={styles.leadsFilter__cancelButton}
          variant="contained"
          sx={{
            backgroundColor: '#fff',
            height: 40,
            "&:hover": { backgroundColor: '#fff' },
            color: '#191F2E',
            boxShadow: 'none'
          }}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  )
}

