import React from 'react'
import { getUserInformationFromlocalStorage } from '../utils/general/get_set_user_information'
import { NotAllowedPage } from '../pages/notAllowedPage/NotAllowedPage';
import { useNavigate } from 'react-router-dom';
import { TeamManagementPage } from '../pages/teamManagementPage/TeamManagementPage';

export const TeamManagementGuard = () => {
  const userInformation = getUserInformationFromlocalStorage()
  const navigate = useNavigate();
  const isAllowed = userInformation.organizationId !== 'none'

  if (!isAllowed) {
    navigate('/');
  }

  return isAllowed ? <TeamManagementPage /> : <NotAllowedPage />;
}