import styles from '../TableTeamManagement.module.scss'
import * as React from 'react';
import { Box, TableCell, TableHead, TableRow } from "@mui/material";
import { headCells } from "../../../utils/pages/teamManagmentPage/headCells";

export const TableTeamManagmentHeader = () => {
  return (
    <>
      <TableHead className={styles.tableHead}>
        <TableRow className={styles.tableHead__row}>
          {headCells.map((headCell) => {
            const isFirstColumn = headCell.id === 'firstName';
            return (
              <TableCell
                key={headCell.id}
                className={styles.tableHead__cell}
                sx={{
                  position: isFirstColumn ? 'sticky' : '',
                  left: isFirstColumn ? 0 : 'none',
                  background: 'white',
                  zIndex: isFirstColumn ? '10' : 'default',
                }}
              >
                <Box
                  className={styles.tableHead__cellContainer}
                  sx={{
                    boxShadow: isFirstColumn ? '1px 0px 2px 0px rgba(0,0,0,0.15)' : 'none',
                    maxWidth: isFirstColumn ? '250px' : 'auto',
                    minWidth: isFirstColumn ? '250px' : 'auto',
                  }}>
                  {headCell.label}
                </Box>
              </TableCell>
            )
          })}
        </TableRow>
      </TableHead >
    </>
  );
}
