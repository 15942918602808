import React, { useState } from 'react';
import styles from "./PopupAddLead.module.css";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button } from '@material-ui/core';
import { styled } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { Post } from '../../../utils/general/https';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';
import { useNotificationActions } from '../../../utils/redux/storeHooks/actionsHooks/useNotificationActions';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { useSidebarActions } from '../../../utils/redux/storeHooks/actionsHooks/useSidebarActions';
import { useSidebarData } from '../../../utils/redux/storeHooks/dataHooks/useSidebarData';
import { useTableLeadsActions } from '../../../utils/redux/storeHooks/actionsHooks/useTableLeadsActions';
import { useTableLeadsData } from '../../../utils/redux/storeHooks/dataHooks/useTableLeadsData';
import { getPageFromTo } from '../../../utils/general/getPageFromTo';

const CssTextField = styled(TextField)({
  '.MuiInputBase-input:-internal-autofill-selected': {
    backgroundColor: 'red',
  },
  '& label': {
    color: 'black',
  },
  '& label.Mui-focused': {
    color: '#6F7D9B',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#6F7D9B',
  },
  '& .MuiOutlinedInput-root': {
    height: '40px',
    width: '550px',
    '& fieldset': {
      borderColor: '#6F7D9B',
      borderRadius: '4px'
    },
    '&:hover fieldset': {
      borderColor: '#6F7D9B',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6F7D9B',
    },
    borderRadius: '4px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '12px 14px', // adjust the space around the input text
    color: '#191F2E'
  },
  '& .MuiInputBase-input': {
    height: '40px', // set the height value as per your requirement
  },
});

export const PopupAddLead = () => {
  const [url, setValue] = useState("");
  const [loadingNewReplyText, setLoadingNewReplyText] = useState(false)
  const { setPopupAddLeadOpen } = usePopupActions()
  const { openSnackBar } = useNotificationActions()
  const { setTotalCountOfLeads } = useSidebarActions()
  const { totalCountOfLeads } = useSidebarData()
  const { getLeadList } = useTableLeadsActions()
  const { rowsPerPage } = useTableLeadsData()
  const closePopupAddLead = () => {
    setPopupAddLeadOpen(false);
  };

  const addLead = async () => {
    setLoadingNewReplyText(true)
    try {
      const data = await Post('/lead-information', { url }, true);
      if (data) {
        setLoadingNewReplyText(false)
        closePopupAddLead();
        if (data.status === 500 || data.status === 403 || data.status === 400) {
          openSnackBar({
            alertTitle: 'Error when adding',
            severenity: 'error',
            message: 'Something went wrong'
          })
        } else {
          openSnackBar({
            alertTitle: 'Lead Added',
            severenity: 'success',
            message: `${data.firstName} ${data.lastName} has been added to your watchlist.`
          })
          setTotalCountOfLeads(totalCountOfLeads + 1)
          const { pageFrom, pageTo } = getPageFromTo(0, rowsPerPage);
          getLeadList({
            from: pageFrom,
            to: pageTo,
            order: 'ASC',
            orderBy: 'firstName'
          })
        }
      }
    } catch {
      openSnackBar({
        severenity: 'error',
      })
    }
  }

  return (
    <>
      <div className={styles.popupAddLead}>
        <div className={styles.addLeadParent}>
          <div className={styles.addLead}>Add Lead</div>
          <IconButton aria-label="delete" color="default" onClick={closePopupAddLead} className={styles.closePopUpBtn} >
            <CloseRoundedIcon />
          </IconButton>
        </div>
        <div className={styles.command}>
          <div className={styles.titleRequired}>
            <div className={styles.div}>*</div>
            <div className={styles.linkToLeads}>
              Link to Lead’s LinkedIn account:
            </div>
          </div>
          <CssTextField
            value={url}
            onChange={(e) => { setValue(e.target.value) }}
          />
        </div>
        <div className={styles.buttons}>
          <div className={styles.button}>
            <Button className={styles.closeBtn} onClick={closePopupAddLead}>Cancel</Button>
          </div>
          <div className={styles.buttons1}>
            <div className={styles.button1}>
              {!loadingNewReplyText && (<AddRoundedIcon
                className={styles.closeBlack24dp1Icon}
              />)}
              < LoadingButton
                loading={loadingNewReplyText}
                loadingPosition="center"
                loadingIndicator={<CircularProgress color='info' size={24} />}
                onClick={addLead}
                className={styles.addLeadBtn}
              >
                Add Lead
                {!loadingNewReplyText && (<div style={{ display: 'flex', backgroundColor: 'red' }}>
                </div>)}
              </LoadingButton>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};
