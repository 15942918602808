import { Box, Typography, Zoom } from "@mui/material";
import React from "react";
import { MainActionButton } from "../../../components/actionButton/MainActionButton";
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { usePopupActions } from "../../../utils/redux/storeHooks/actionsHooks/usePopupActions";
import { useSidebarData } from "../../../utils/redux/storeHooks/dataHooks/useSidebarData";
import { TooltipApp } from "../../../components/tooltipApp/TooltipApp";
import PersonAddIcon from '@mui/icons-material/PersonAdd';

export const ImportDropDownMenu = () => {
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { setPopUpAddLeadsUseSnOpen } = usePopupActions();
  const { totalCountOfLeads, leadsLimit } = useSidebarData()

  // const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const openPopLoadSnLink = () => {
    setPopUpAddLeadsUseSnOpen(true)
    // setAnchorEl(null);
  }

  if (totalCountOfLeads >= leadsLimit) {
    return (
      <TooltipApp TransitionComponent={Zoom} placement="bottom-start" title={
        <>
          <Typography sx={{ fontSize: 14 }}>
            {"Sorry, you've reached the limit for tracking leads. Please contact the admin."}
          </Typography>
        </>
      }>
        <Box>
          <MainActionButton
            title='Add leads'
            icon={<PersonAddIcon />}
            actionOnClick={openPopLoadSnLink}
            isDisabled={totalCountOfLeads >= leadsLimit}

          />
          {/* <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            PaperProps={{
              style: {
                boxShadow: '4px 4px 8px 0px rgba(0, 0, 0, 0.15)',
                width: '220px',
                height: '180px',
                overflowX: 'unset',
                overflowY: 'unset',
              },
            }}
          >
            <MenuItem
              sx={{
                height: '40px',
              }}
              disabled
            >
              <ListItemIcon>
                <img alt="" src="/importCsv.svg" />
              </ListItemIcon>
              <ListItemText>
                <Typography sx={{ fontFamily: 'Kanit', fontWeight: 300, fontSize: 16 }}>
                  Import CSV
                </Typography>
              </ListItemText>
            </MenuItem>

            <MenuItem
              sx={{
                height: '40px',
              }}
              onClick={openPopLoadSnLink}
            >
              <ListItemIcon>
                <img alt="" src="/snIcon.svg" />
              </ListItemIcon>
              <ListItemText>
                <Typography sx={{ fontFamily: 'Kanit', fontWeight: 300, fontSize: 16 }}>
                  Use Sales Navigator
                </Typography>
              </ListItemText>
            </MenuItem>
          </Menu> */}
        </Box>
      </TooltipApp>
    )
  }

  return (
    <>
      <MainActionButton
        title='Add leads'
        icon={<PersonAddIcon />}
        actionOnClick={openPopLoadSnLink}
        isDisabled={totalCountOfLeads >= leadsLimit}
      />
      {/* <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            boxShadow: '4px 4px 8px 0px rgba(0, 0, 0, 0.15)',
            width: '220px',
            height: '90px',
            overflowX: 'unset',
            overflowY: 'unset',
          },
        }}
      >
        <MenuItem
          sx={{
            height: '40px',
          }}
          disabled
        >
          <ListItemIcon>
            <img alt="" src="/importCsv.svg" />
          </ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontFamily: 'Kanit', fontWeight: 300, fontSize: 16 }}>
              Import CSV
            </Typography>
          </ListItemText>
        </MenuItem>

        <MenuItem
          sx={{
            height: '40px',
          }}
          onClick={openPopLoadSnLink}
        >
          <ListItemIcon>
            <img alt="" src="/snIcon.svg" />
          </ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontFamily: 'Kanit', fontWeight: 300, fontSize: 16 }}>
              Use Sales Navigator
            </Typography>
          </ListItemText>
        </MenuItem>
      </Menu> */}
    </>
  )
}

