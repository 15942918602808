import { Box } from '@mui/material';
import * as React from 'react';
import style from '../../botManagementPage.module.scss'


export const BotStatisticHeader = () => {
  return (
    <Box className={style.botManagement__botsStatisticHeader}>
      <Box
        sx={{ width: 48 }}
        className={style.botManagement__headerStatisticBlock}
      />
      <Box
        sx={{ width: 80 }}
        className={style.botManagement__headerStatisticBlock}
      >
        Name
      </Box>
      <Box
        sx={{ width: 80 }}
        className={style.botManagement__headerStatisticBlock}
      >
        Work name
      </Box>
      <Box
        sx={{ width: 80 }}
        className={style.botManagement__headerStatisticBlock}
      >
        Total processed leads for today
      </Box>
      <Box
        sx={{ width: 80 }}
        className={style.botManagement__headerStatisticBlock}
      >
        Total processed leads for Month
      </Box>
      <Box
        sx={{ width: 60 }}
        className={style.botManagement__headerStatisticBlock}
      >
        Last work date
      </Box>
      <Box
        className={style.botManagement__headerStatisticBlock}
        sx={{ width: 50 }}
      >
        Is last work done
      </Box>
      <Box
        className={style.botManagement__headerStatisticBlock}
        sx={{ width: 50 }}
      >
        Is last work error
      </Box>
      <Box
        className={style.botManagement__headerStatisticBlock}
        sx={{ width: 250 }}
      >
        Error
      </Box>
    </Box>
  )
}

