import { Box, Slider } from '@mui/material';
import * as React from 'react';
import { useAutomationData } from '../../../../utils/redux/storeHooks/dataHooks/useAutomationData';
import { NameOfSlider } from '../../../../types/pages/automationPage/NameOfSlider';
import { valuetext } from '../../../../utils/pages/automationPage/valueText';
import scss from '../../automation_page.module.scss'

type Props = {
  handleChangeSlider: (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
    name: string
  ) => void;
}
export const AutomationDailyCommentsQouta = ({ handleChangeSlider }: Props) => {
  const {
    dailyCommentsQouta,
  } = useAutomationData();

  return (
    <Box>
      <Slider
        getAriaLabel={() => 'Minimum distance shift'}
        value={dailyCommentsQouta}
        onChange={(event, newValue, activeThumb) => {
          handleChangeSlider(event, newValue, activeThumb, NameOfSlider.dailyCommentsQuota)
        }}
        valueLabelDisplay="auto"
        min={50}
        step={50}
        max={500}
        getAriaValueText={valuetext}
        sx={{ color: '#3B2470' }}
      />
      <Box className={scss.setting__footer}>
        <Box className={scss.setting__footerTitle}>
          Number of comments:
          <Box className={scss.setting__parametr}>
            {dailyCommentsQouta}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

