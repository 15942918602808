import { useAppSelector } from '../typedHooks';

export const useTableTeamManagementData = () => {
  const {
    rows,
    loading,
    organizationRole,
    filteredTeamMembers,
    deletingRowId
  } = useAppSelector((state) => state.tableTeamManagementReducer);

  return {
    rows,   
    loading,
    organizationRole,
    filteredTeamMembers,
    deletingRowId
  };
};