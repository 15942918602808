//value there is count of last days
export const options = [
  { title: 'today', value: 0 },
  { title: 'yesterday', value: 1 },
  { title: 'last 3 days', value: 3 },
  { title: 'last 5 days', value: 5 },
  { title: 'last 7 days', value: 7 },
  { title: 'last 15 days', value: 15 },
  { title: 'last 30 days', value: 30 },
  { title: 'Select date', value: 0 }
]