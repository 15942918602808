import { HeadCell } from "../../../../types/pages/dashboardLeadsPeoplePage/headCell";

export const headCells: readonly HeadCell[] = [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    disableSorting: false,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 150,
    minWidth: 150,
  },
  {
    id: 'Connection',
    numeric: true,
    disablePadding: false,
    label: 'Connection',
    disableSorting: true,
    showToolTip: true,
    textForToolTip: `Linkedin connection position`,
    maxWidth: 50,
    minWidth: 50,
  },
  {
    id: 'companyName',
    numeric: false,
    disablePadding: false,
    label: 'Company',
    disableSorting: false,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 150,
    minWidth: 150,
  },
  {
    id: 'myCommentsCount',
    numeric: true,
    disablePadding: false,
    label: 'My comments',
    disableSorting: true,
    showToolTip: true,
    textForToolTip: `Total count of comments I've made on Lead's posts.`,
    maxWidth: 50,
    minWidth: 50,
  },
  {
    id: 'Replies',
    numeric: true,
    disablePadding: false,
    label: 'Replies',
    disableSorting: true,
    showToolTip: true,
    textForToolTip: `Total count of leads replies.`,
    maxWidth: 50,
    minWidth: 50,
  },
  {
    id: 'ReplyOnComments',
    numeric: true,
    disablePadding: false,
    label: 'Actions',
    disableSorting: true,
    showToolTip: true,
    textForToolTip: 'Action on the last comment',
    maxWidth: 200,
    minWidth: 200,
  },
  {
    id: 'Contact Lead',
    numeric: true,
    disablePadding: false,
    label: 'Contact Lead',
    disableSorting: true,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 50,
    minWidth: 50,
  },
  {
    id: 'Delete',
    numeric: true,
    disablePadding: false,
    label: '',
    disableSorting: true,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 50,
    minWidth: 50,
  }
];