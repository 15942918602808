import * as React from 'react';
import scss from '../TableLeads.module.scss';
import { Box, TableCell, TableHead, TableRow, TableSortLabel, Zoom } from "@mui/material";
import { TooltipApp } from "../../tooltipApp/TooltipApp";
import SortRoundedIcon from '@mui/icons-material/SortRounded';
import { useStyles } from "../../../utils/pages/dashboardLeadsPeoplePage/tableLeads/useStyles";
import { useTableLeadsData } from "../../../utils/redux/storeHooks/dataHooks/useTableLeadsData";
import { useTableLeadsActions } from "../../../utils/redux/storeHooks/actionsHooks/useTableLeadsActions";
import { RedCheckbox } from "../../../utils/pages/dashboardLeadsPeoplePage/redCheckbox";
import { headCells } from '../../../utils/pages/dashboardLeadsPeoplePage/tableLeads/headCells';
import { useEffect } from 'react';

type Props = {
  scrollUp: () => void;
}

export const TableLeadsHeader = ({ scrollUp }: Props) => {
  const { order, orderBy, rows, selected, page, isAllSelected } = useTableLeadsData()
  const { setSelected, setOrder, setOrderBy, setPage, setIsAllSelected } = useTableLeadsActions()
  const classes = useStyles();

  const handleRequestSort = (
    property: string,
  ) => {
    const insideOrder = order === 'ASC' ? 'DESC' : 'ASC'
    setOrderBy(property)
    setOrder(insideOrder);
    setPage(0)
    scrollUp();
  };

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAllSelected(false)
    if (event.target.checked) {
      const newSelected = rows.map((n) => String(n.id));
      setSelected(newSelected);
      return;
    }

    setSelected([]);
  };

  useEffect(() => {
    if (isAllSelected) {
      const newSelected = rows.map((n) => String(n.id));
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows]);

  return (
    <>
      <TableHead sx={{
        border: '0px',
        width: '100%',
      }}
      >
        <TableRow sx={{ zIndex: 0 }}>
          <TableCell sx={{
            minWidth: 30,
            maxWidth: 30,
            padding: '0px 12px',
            zIndex: 100,
          }}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <RedCheckbox
                indeterminate={selected.length > 0 && selected.length < rows.length}
                checked={rows.length > 0 && selected.length === rows.length}
                onChange={handleSelectAllClick}
                inputProps={{
                  'aria-label': 'select all desserts',
                }}
              />
            </Box>
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={
                headCell.id === 'firstName'
                  || headCell.id === 'companyName'
                  || headCell.id === 'dateCreated'
                  ? 'left'
                  : 'center'
              }
              sx={{
                width: 'auto',
                height: '64px',
                fontFamily: 'Kanit',
                fontSize: '16px',
                lineHeight: '27px',
                color: '#191F2E',
                padding: '0px 12px',
                zIndex: 100,
              }}
            >
              {headCell.showToolTip &&
                <TooltipApp TransitionComponent={Zoom} placement="top" title={<><span className={scss.toolTipBody}>{headCell.textForToolTip}</span></>}>
                  {headCell.disableSorting ? (<Box>{headCell.label}</Box>) : (<TableSortLabel
                    active={orderBy === headCell.id}
                    direction={order === 'ASC' ? 'asc' : 'desc'}
                    onClick={createSortHandler(headCell.id)}
                    IconComponent={SortRoundedIcon}
                    classes={{
                      root: orderBy === headCell.id ? classes.sortedLabel : '',
                      icon: orderBy === headCell.id ? classes.sortedIcon : '',
                    }}
                  >
                    {headCell.label}
                  </TableSortLabel>)}
                </TooltipApp>
              }

              {!headCell.showToolTip &&
                <>
                  {headCell.disableSorting ? (<>{headCell.label}</>) : (<TableSortLabel
                    active={orderBy === headCell.id}
                    direction={order === 'ASC' ? 'asc' : 'desc'}
                    onClick={createSortHandler(headCell.id)}
                    IconComponent={SortRoundedIcon}
                    classes={{
                      root: orderBy === headCell.id ? classes.sortedLabel : '',
                      icon: orderBy === headCell.id ? classes.sortedIcon : '',
                    }}
                  >
                    {headCell.label}
                  </TableSortLabel>)}
                </>}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
}
