import * as React from 'react';
import style from '../../newFeedPage.module.scss'
import { Autocomplete, AutocompleteRenderInputParams, Box, Chip, TextField, styled } from '@mui/material';
import { KeywordInputsType } from '../../../../types/pages/newFeedPage/keyWordInput';
import { useParams } from 'react-router-dom';
import { useNewFeedActions } from '../../../../utils/redux/storeHooks/actionsHooks/useNewFeedActions';
import { useNewFeedData } from '../../../../utils/redux/storeHooks/dataHooks/useNewFeedData';
// .css-1wc848c-MuiFormHelperText-root
const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiOutlinedInput-root': {
    padding: '1px 12px',
    borderRadius: '1px',
    fontFamily: 'Kanit',
    width: '100%',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(109, 48, 183, 1)',
      boxShadow: 'rgba(109, 48, 183, 1) 0px 1px 3px'
    },
    '&.MuiFormHelperText-root': {
      fontFamily: 'Kanit',
    }
  },
})

type Props = {
  keyWordInputType: KeywordInputsType;
  title: string;
}

export const KeyWordInput = ({ keyWordInputType, title }: Props) => {
  const { tabId } = useParams();
  const { setIncludesKeywordForTab, setExcludesKeywordForTab } = useNewFeedActions();
  const { tabs } = useNewFeedData();
  let keywordOptions: string[] = [];
  let color = '#E0E8F9';
  const inputError = { isError: false, errorText: 'You cannot add more than 5 key words' }

  const setkeyWordInputType = () => {
    const currentTab = tabs.find(tab => tab.id === tabId)
    switch (keyWordInputType) {
      case KeywordInputsType.include:
        keywordOptions = tabs.find(tab => tab.id === tabId)?.includeKeywords || [];
        color = '#E0E8F9'
        inputError.isError = currentTab.includeKeywords.length > 5
        return;
      case KeywordInputsType.exclude:
        keywordOptions = tabs.find(tab => tab.id === tabId)?.excludeKeywords || [];
        color = '#FFDBD1'
        inputError.isError = currentTab.excludeKeywords.length > 5
        return;
    }
  }

  const handleAutocompleteChange = (_: React.SyntheticEvent<Element, Event>, newValue: string[]) => {
    switch (keyWordInputType) {
      case KeywordInputsType.include:
        setIncludesKeywordForTab({ newValue, tabId })
        return;
      case KeywordInputsType.exclude:
        setExcludesKeywordForTab({ newValue, tabId })
        return;
    }
  }

  setkeyWordInputType();

  return (
    <Box className={style.actions__keywordInputWrapper}>
      <Box className={style.actions__inputTitle}>{title}</Box>
      <StyledAutocomplete
        multiple
        limitTags={-1}
        id="tags-filled"
        options={[]}
        value={keywordOptions}
        onChange={(event, newValue: string[]) => handleAutocompleteChange(event, newValue)}
        freeSolo
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              key={option}
              label={option}
              style={{
                backgroundColor: color,
                margin: '2px',
                borderRadius: '1px',
                fontFamily: 'Kanit'
              }}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            placeholder={keywordOptions.length === 0 ? 'maximum of five keywords' : ''}
            style={{ fontFamily: 'Kanit' }}
            error={inputError.isError}
            helperText={inputError.isError && inputError.errorText}
          />
        )}
      />
    </Box>

  )
}

