import styles from '../TableImportCSV.module.scss'
import * as React from 'react';
import { Box, TableCell, TableHead, TableRow } from "@mui/material";
import { useTableImportCSVData } from '../../../utils/redux/storeHooks/dataHooks/useTableImportCSVData';
import { useTableImportCSVActions } from '../../../utils/redux/storeHooks/actionsHooks/useTableImportCSVActions';
import { RedCheckbox } from '../../../utils/pages/dashboardLeadsPeoplePage/redCheckbox';
import { headCells } from '../../../utils/pages/dashboardLeadsPeoplePage/tableImportCSV/headCells';

export const TableImportCSVHeader = () => {
  const { rows, selectedImports } = useTableImportCSVData();
  const { setSelectedImports } = useTableImportCSVActions();

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.documentUuid);
      setSelectedImports(newSelected);
      return;
    }

    setSelectedImports([]);
  };

  return (
    <>
      <TableHead className={styles.tableHead}>
        <TableRow className={styles.tableHead__row}>
          <TableCell className={styles.tableHead__cell} sx={{ width: '50px' }}>
            <Box className={styles.tableHead__cellContainer}>
              <RedCheckbox
                indeterminate={selectedImports.length > 0 && selectedImports.length < rows.length}
                checked={rows.length > 0 && selectedImports.length === rows.length}
                onChange={handleSelectAllClick}
                inputProps={{
                  'aria-label': 'select all desserts',
                }}
              />
            </Box>
          </TableCell>
          {headCells.map((headCell) => {
            return (
              <TableCell
                key={headCell.id}
                className={styles.tableHead__cell}
              >
                <Box className={styles.tableHead__cellContainer}>
                  {headCell.label}
                </Box>
              </TableCell>
            )
          })}
          <TableCell sx={{ padding: '0px 12px' }}>
          </TableCell>
        </TableRow>
      </TableHead >
    </>
  );
}
