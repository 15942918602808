import * as React from 'react';
import style from '../../botManagementPage.module.scss'
import { WorkerList } from '../actions/workerList';
import { Box, Typography } from '@mui/material';
import { useBotManagementData } from '../../../../utils/redux/storeHooks/dataHooks/useBotManagementData';
import { useBotManagementActions } from '../../../../utils/redux/storeHooks/actionsHooks/botManagementActions';
import { MainActionButton } from '../../../../components/actionButton/MainActionButton';
import { SelectLink } from '../actions/selectLink';

export const InitialFeedTab = () => {
  const {
    initialFeedWorkerList,
    isLoadingRunCheckFeedInitial,
    selectedSnLinkToInitialParse,
  } = useBotManagementData();
  const { removeFromInitialFeedWorkList, checkFeedInitial } = useBotManagementActions();

  const onCheckFeedInitial = () => {
    if (selectedSnLinkToInitialParse) {
      checkFeedInitial({
        botsUuids: initialFeedWorkerList.map(bot => bot.uidd),
        userUuid: selectedSnLinkToInitialParse.userUuid,
        snLinkId: selectedSnLinkToInitialParse.id
      })
    }
  }

  return (
    <Box className={style.botManagement__actions_content}>
      <WorkerList workerList={initialFeedWorkerList} removeFromWorkList={removeFromInitialFeedWorkList} />
      {/* <Box className={style.botManagement__actions_selectUser}>
        <SelectUser />
      </Box> */}
      <Box className={style.botManagement__actions_selectUser}>
        <SelectLink />
      </Box>
      <Box className={style.botManagement__actions_runParsing}>
        <Typography className={style.botManagement__actions_actionTitle}>
          Action:
        </Typography>
        <MainActionButton
          title='Run parsing'
          height={50}
          actionOnClick={onCheckFeedInitial}
          isLoading={isLoadingRunCheckFeedInitial}
          isDisabled={
            (initialFeedWorkerList?.length === 0)
            || isLoadingRunCheckFeedInitial
            || !selectedSnLinkToInitialParse
          }
        />
      </Box>
    </Box>
  )
}

