import { useAppSelector } from '../typedHooks';

export const useAdminPageData = () => {
  const {
    users,
    filtredUsers,
    usersCount,
    isUsersLoading,
    teams,
    teamsCount,
    isTeamsLoading,
    manageCurrentUser,
    generatedPassword,
    isPasswordGenerating,
    isSendingExtension,
    isSendingCredentials,
    isUserUpdating,
    isDeletingUser,
    manageCurrentTeam,
    isCreatingTeam,
    isUpdatingTeam,
    isDeletingTeam,
    isUserRepairing
  } = useAppSelector((state) => state.adminPageReducer);

  return {
    users,
    filtredUsers,
    usersCount,
    isUsersLoading,
    teams,
    teamsCount,
    isTeamsLoading,
    manageCurrentUser,
    generatedPassword,
    isPasswordGenerating,
    isSendingExtension,
    isSendingCredentials,
    isUserUpdating,
    isDeletingUser,
    manageCurrentTeam,
    isCreatingTeam,
    isUpdatingTeam,
    isDeletingTeam,
    isUserRepairing
  };
};