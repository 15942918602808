import React from "react";
import styles from "../PagesTabs.module.scss";
import { NavLink, useParams } from "react-router-dom";
import { DropDownTabMenu } from "./DropDownTabMenu";
import { Avatar, Badge, Box, CircularProgress, Divider } from "@mui/material";
import { useNewFeedData } from "../../../utils/redux/storeHooks/dataHooks/useNewFeedData";

type Props = {
  tabId: string;
  title: string;
  link: string;
  feedCount?: number;
}

export const FeedTab = ({ title, link, tabId: currentTabId, feedCount }: Props) => {
  const { tabId } = useParams();
  const { totalFeedCount, isGetFeedCountLoading } = useNewFeedData();
  const currentTitle = title.length > 18 ? `${title.slice(0, 15)}...` : title

  return (
    <>
      <NavLink
        key={title}
        end
        className={({ isActive }) => {
          return isActive
            ? `${styles.pagesTabs__tab} ${styles.activeLink}`
            : styles.pagesTabs__tab
        }}
        title={title}
        to={link}
      >

        {currentTitle}
        <Divider orientation="vertical" sx={{ height: '20px' }} />
        {feedCount && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {`(${feedCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`}
        </Box>}
        {currentTabId === 'general' && (
          <Badge
            overlap="circular"
            badgeContent={
              <Avatar
                alt=""
                src="/Ellipse_red.svg"
                variant="rounded"
                sx={{ width: '8px', height: '8px', position: 'absolute', right: -5 }}
              />
            }
          >
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {
                isGetFeedCountLoading
                  ? <CircularProgress size={20} sx={{ color: '#3B2470' }} />
                  : `(${totalFeedCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})`
              }
            </Box>

          </Badge>
        )}
        {currentTabId !== 'general'
          && tabId === currentTabId
          && <DropDownTabMenu />}
      </NavLink>
    </>
  )
}
