import { Checkbox, styled } from "@mui/material";

export const RedCheckbox = styled(Checkbox)`
  color: #3B2470;
  padding: 0;
  &.Mui-checked {
    color: #3B2470;
  }

  &.MuiCheckbox-indeterminate {
    color: #3B2470;
  }
`;