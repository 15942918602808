import * as React from 'react';
import style from '../../botManagementPage.module.scss'
import { Box, TextField, Typography } from '@mui/material';
import { useBotManagementData } from '../../../../utils/redux/storeHooks/dataHooks/useBotManagementData';
import { useBotManagementActions } from '../../../../utils/redux/storeHooks/actionsHooks/botManagementActions';
import { MainActionButton } from '../../../../components/actionButton/MainActionButton';
import { SelectUser } from '../actions/selectUser';
import { useState } from 'react';
import { WorkerList } from '../actions/workerList';


export const LeadsTab = () => {
  const { isLoadingRunLoadLeadsSnGet, selectedUserForLoadLeads, leadsWorkerList } = useBotManagementData();
  const { loadLeadsSnGet, removeFromLeadsWorkList } = useBotManagementActions();
  const [link, setLink] = useState('');
  const onRunLoadLeadsHandler = () => {
    if (link.length && leadsWorkerList[0] && selectedUserForLoadLeads) {
      loadLeadsSnGet({
        botUuid: leadsWorkerList[0].uidd,
        userUuid: selectedUserForLoadLeads.uidd,
        snLink: link,
      })
    }
  }

  return (
    <Box className={style.botManagement__actions_content}>
      <WorkerList workerList={leadsWorkerList} removeFromWorkList={removeFromLeadsWorkList} />
      <Box className={style.botManagement__actions_linkInputContainer}>
        <Typography className={style.botManagement__actions_actionTitle}>
          Paste SN Link
        </Typography>
        <TextField
          id="outlined-controlled"
          label="SN link"
          value={link}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setLink(event.target.value);
          }}
        />
      </Box>

      <Box className={style.botManagement__actions_selectUser}>
        <SelectUser />
      </Box>

      <Box className={style.botManagement__actions_runParsing}>
        <Typography className={style.botManagement__actions_actionTitle}>
          Action:
        </Typography>
        <MainActionButton
          title='Run parsing'
          height={50}
          actionOnClick={onRunLoadLeadsHandler}
          isLoading={isLoadingRunLoadLeadsSnGet}
          isDisabled={isLoadingRunLoadLeadsSnGet
            || !selectedUserForLoadLeads
            || !link.length
            || !leadsWorkerList[0]
          }
        />
      </Box>
    </Box>
  )
}

