import { Routes, Route, useLocation, Navigate, } from "react-router-dom";
import { DashboardLeadsPeoplePage } from "./pages/dashboardLeadsPeoplePage/DashboardLeadsPeoplePage";
import { useEffect } from "react";
import React from "react";
import AutomationPage from "./pages/automationPage/automation_page";
import { LayoutApp } from "./components/layouts/LayoutApp";
import { useSidebarActions } from "./utils/redux/storeHooks/actionsHooks/useSidebarActions";
import { TableLeads } from "./components/tableLeads/TableLeads";
import { TeamManagementGuard } from "./route-guard/route-guard-teamManagement";
import { TableImportCSV } from "./components/tableImportsCSV/TableImportCSV";
import { NewFeedPage } from "./pages/newFeedPage/newFeedPage";
import { DailyFeedTab } from "./pages/botManagementPage/features/tabs/dailyFeedTab";
import { InitialFeedTab } from "./pages/botManagementPage/features/tabs/initialFeedTab";
import { LeadsTab } from "./pages/botManagementPage/features/tabs/leadsTab";
import SignIn from "./pages/authentication/signIn/signIn";
import RestorePassword from "./pages/authentication/restore_password/restore_password";
import { excludePathesToRequest } from "./utils/general/excludePathesToRequestInAppComponent";
import { TableUsers } from "./pages/adminPage/features/tableUsers/TableUsers";
import { TableTeams } from "./pages/adminPage/features/tableTeams/TableTeams";
import { PromptSettingPage } from "./pages/promptSettingsPage/PromptSettingsPage";
import { BotManagementGuard } from "./route-guard/route-guard-botManagement";
import { AdminPageGuard } from "./route-guard/route-guard-AdminPage";
import { NotAllowedPage } from "./pages/notAllowedPage/NotAllowedPage";

function App() {
  const pathname = useLocation().pathname;
  const { checkValidCookie } = useSidebarActions()

  useEffect(() => {
    if (!excludePathesToRequest.includes(pathname)) {
      checkValidCookie()
    }
  }, [pathname, checkValidCookie])

  return (
    <Routes>
      <Route path="SignIn" element={<SignIn />} />
      <Route path="automation" element={<AutomationPage />} />
      <Route path="restore-pass" element={<RestorePassword />} />
      <Route path="prompts-manager" element={<PromptSettingPage />} />
      <Route path="bot-management" element={<BotManagementGuard />} >
        <Route index element={<DailyFeedTab />} />
        <Route path="/bot-management/initial-feed" element={<InitialFeedTab />} />
        <Route path="/bot-management/leads" element={<LeadsTab />} />
      </Route>
      <Route path="admin-page" element={<AdminPageGuard />} >
        <Route path="/admin-page/users" element={<TableUsers />} />
        <Route path="/admin-page/teams" element={<TableTeams />} />
      </Route>
      <Route path="/" element={<LayoutApp />}>
        <Route index element={<Navigate to="/leads" />} />
        <Route path="team-management" element={<TeamManagementGuard />} />
        <Route path="leads" element={<DashboardLeadsPeoplePage />} >
          <Route index element={<TableLeads />} />
          <Route path="/leads/import" element={<TableImportCSV />} />
        </Route>
        <Route path="feed" element={<Navigate to="/feed/general" replace />} />
        <Route path="feed/:tabId" element={<NewFeedPage />} />
      </Route>
      <Route path="*" element={<NotAllowedPage />} />
    </Routes>
  );
}

export default App;
