import { Box, CircularProgress, Divider, LinearProgress, Typography } from '@mui/material';
import * as React from 'react';
import style from '../../botManagementPage.module.scss'
import { useBotManagementData } from '../../../../utils/redux/storeHooks/dataHooks/useBotManagementData';
import { useBotManagementActions } from '../../../../utils/redux/storeHooks/actionsHooks/botManagementActions';
import { useEffect } from 'react';
import { BotStatisticCard } from './botStatisticCard';
import { BotStatisticCharts } from './BotStatisticCharts';
import { BotStatisticHeader } from './botStatisticHeader';


export const BotStatistic = () => {
  const { botStatistic, isStatisticLoading } = useBotManagementData()
  const { getBotStatistic } = useBotManagementActions()

  useEffect(() => {
    getBotStatistic()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isStatisticLoading) {
    return (
      <Box className={style.botManagement__statistic} boxShadow={2}>
        <Typography className={style.botManagement__mainTitle}>
          Bot`s statistic
        </Typography>
        <LinearProgress sx={{ width: '100%' }} color='inherit' />
      </Box>
    )
  }

  return (
    <Box className={style.botManagement__statistic} boxShadow={2}>
      <Typography className={style.botManagement__mainTitle}>
        Bot`s statistic
      </Typography>
      <Divider sx={{ width: '100%' }} />
      <BotStatisticHeader />
      <Divider sx={{ width: '100%' }} />
      <Box className={style.botManagement__statistic_list}>
        {botStatistic.map(bot => {
          return (
            <BotStatisticCard key={bot.id} bot={bot} />
          )
        })}
      </Box>
      <Divider sx={{ width: '100%' }} />
      <Typography className={style.botManagement__mainTitle}>
        Count of leads processed by:
      </Typography>
      <Divider sx={{ width: '100%' }} />
      <BotStatisticCharts />
    </Box>
  )
}

