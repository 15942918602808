import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LeadsNotificationInitialState } from './types';
import { checkActivityByNotification, getNotificationFromDb, updateViewNotificationStatus } from './thunks';
import { LeadActivityOnReply } from '../../../../types/entities/leadActivityOnReply';

const initialState: LeadsNotificationInitialState = {
  notifications: [],
  isNotificationUpdating: false,
  isGettingNotificationFromDb: false,
  isDeletingNotifications: false,
  isSnackBarLoadingOpen: false,
  isNotificationUpdatingComplete: false,
};

export const leadsNotificationSlice = createSlice({
  name: 'leadsNotificationSlice',
  initialState,
  reducers: {
    setNotification: (state, { payload }: PayloadAction<LeadActivityOnReply[]>) => {
      state.notifications = payload;
    },
    setSnackBarOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isSnackBarLoadingOpen = payload;
    },
    setNotificationUpdatingComplete: (state, { payload }: PayloadAction<boolean>) => {
      state.isNotificationUpdatingComplete = payload;
    },      
  },
  extraReducers: (builder) => {
    //updateLeadsNotification
    builder.addCase(checkActivityByNotification.pending, (state, action) => {
      state.isNotificationUpdating = true;
      state.isSnackBarLoadingOpen = true;
      state.isNotificationUpdatingComplete = false;
    })
    builder.addCase(checkActivityByNotification.fulfilled, (state, action) => {
      if(action.payload?.notifications?.length) {
        action.payload.notifications.forEach(notification => {
          const existingNotification = state.notifications.find(note => note.id === notification.id)
          if(!existingNotification) {
            state.notifications.push(notification)
          }
        })        
      }
      state.isNotificationUpdating = false;
      state.isSnackBarLoadingOpen = false;
      state.isNotificationUpdatingComplete = true;
    })
    builder.addCase(checkActivityByNotification.rejected, (state, action) => {
      state.isNotificationUpdating = false;
      state.isSnackBarLoadingOpen = false;
    })
    //getLeadsNotification
    builder.addCase(getNotificationFromDb.pending, (state, action) => {
      state.isGettingNotificationFromDb = true;
    })
    builder.addCase(getNotificationFromDb.fulfilled, (state, action) => {
      state.notifications = action.payload.notifications;
      state.isGettingNotificationFromDb = false;
    })
    builder.addCase(getNotificationFromDb.rejected, (state, action) => {
      state.isGettingNotificationFromDb = false;
    })
    //UpdateViewStatus
    builder.addCase(updateViewNotificationStatus.pending, (state, action) => {
      state.isDeletingNotifications = true;
    }) 
    builder.addCase(updateViewNotificationStatus.fulfilled, (state, action) => {
      state.notifications = [];
      state.isDeletingNotifications = false;
    })                  
  }    
});

export const leadsNotificationActions = leadsNotificationSlice.actions;
export const leadsNotificationReducer = leadsNotificationSlice.reducer;
