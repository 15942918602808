import * as React from 'react';
import scss from '../../AdminPage.module.scss';
import { Box, IconButton, TableBody, TableCell, TableRow } from '@mui/material';
import { useAdminPageData } from '../../../../utils/redux/storeHooks/dataHooks/useAdminPageData';
import DeleteIcon from '@mui/icons-material/Delete';
import { TableLoader } from '../../../../components/tableLoader/TableLoader';
import { usePopupActions } from '../../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { useAdminPageActions } from '../../../../utils/redux/storeHooks/actionsHooks/useAdminPageActions';
import Teams from '../../../../types/entities/teams';
import GroupIcon from '@mui/icons-material/Group';

export const TableTeamsBody = () => {
  const { teams, isTeamsLoading } = useAdminPageData();
  const { setManageCurrentTeam } = useAdminPageActions();
  const { setPopupDeleteTeamOpen, setPopupUpdateTeamOpen } = usePopupActions();

  const onDeleteTeam = (team: Teams) => {
    setManageCurrentTeam(team)
    setPopupDeleteTeamOpen(true)
  };

  const onOpenPopUpManageTeam = (team: Teams) => {
    setManageCurrentTeam(team)
    setPopupUpdateTeamOpen(true)
  };

  return (
    <TableBody sx={{ position: 'relative', color: '#3B2470' }}>
      {isTeamsLoading && teams.length > 0 && <TableLoader />}
      {teams.map((team) => {
        return (
          <TableRow
            hover
            key={team.id}
            sx={{
              maxHeight: '64px'
            }}
          >
            <TableCell sx={{ minWidth: 150, maxWidth: 150, padding: '12px 12px' }}>
              <Box className={scss.profileWrapper}>
                {team.teamName}
              </Box>
            </TableCell>

            <TableCell
              sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }}
              align="center">
              {team.usersCount}
            </TableCell>

            <TableCell sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }} align="center">
              <div>
                <IconButton color="primary" aria-label="dm" onClick={() => onOpenPopUpManageTeam(team)}>
                  <GroupIcon sx={{ color: '#3B2470' }} />
                </IconButton>
              </div>
            </TableCell>

            <TableCell sx={{ minWidth: 50, maxWidth: 50, padding: '0px 12px' }} align="center">
              <div>
                <IconButton color="primary" aria-label="dm" onClick={() => onDeleteTeam(team)}>
                  <DeleteIcon sx={{ color: '#6f7d9b' }} />
                </IconButton>
              </div>
            </TableCell>


          </TableRow>
        );
      })}
    </TableBody>
  )
}