import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreateNewTabPayload, CreateNewTabResponse, DeletePostPayload, DeletePostResponse, DeleteTabPayload, DeleteTabResponse, GenerateMessagePayload, GenerateMessageResponse, GetAllTabsResponse, GetFeedPayload, GetFeedResponse, GetPromptListResponse, SendMessagePayload, SendMessageResponse, UpdateTabPayload, UpdateTabResponse, UpdateTitleTabPayload, UpdateTitleTabResponse } from "./types";
import { deleteRequest, getRequest, postRequest } from "../../../general/https";
import { checkErrorStatus } from "../../../general/checkErrorStatus";

export const getFeed = createAsyncThunk<GetFeedResponse, GetFeedPayload>(
  'newFeedSlice/getFeed',
  async (payload, {rejectWithValue}) => {
    const {from, to, tabId} = payload;
    try {
      const response = await getRequest(`/new-feed/get-feed/${from}/${to}/${tabId}`, true)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getFeedCount = createAsyncThunk<number>(
  'newFeedSlice/getFeedCount',
  async (_, {rejectWithValue}) => {
    try {
      const response = await getRequest(`/new-feed/get-feed-count`, true)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getAllTabs = createAsyncThunk<GetAllTabsResponse>(
  'newFeedSlice/getAllTabs',
  async (_, {rejectWithValue}) => {
    try {
      const response = await getRequest(`/new-feed/get-all-tabs`, true)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const createNewTab = createAsyncThunk<CreateNewTabResponse, CreateNewTabPayload>(
  'newFeedSlice/createNewTab',
  async (payload, {rejectWithValue}) => {
    const {body} = payload;
    try {
      const response = await postRequest(`/new-feed/create-feed-tab`, body, true)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const deleteTab = createAsyncThunk<DeleteTabResponse, DeleteTabPayload>(
  'newFeedSlice/deleteTab',
  async ({tabId}, {rejectWithValue}) => {
    try {
      const response = await deleteRequest(`/new-feed/delete-feed-tab/${tabId}`, true)
      checkErrorStatus(response)
      return {isDeleted: true, tabId}
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const updateTab = createAsyncThunk<UpdateTabResponse, UpdateTabPayload>(
  'newFeedSlice/updateTab',
  async ({id, includeKeywords, excludeKeywords, daysOption}, {rejectWithValue}) => {
    const body = { includeKeywords, excludeKeywords, daysOption }
    try {
      const response = await postRequest(`/new-feed/update-feed-tab-filters/${id}`,body, true)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const updateTitleTab = createAsyncThunk<UpdateTitleTabResponse, UpdateTitleTabPayload>(
  'newFeedSlice/updateTitleTab',
  async ({tabId, newTitle}, {rejectWithValue}) => {
    const body = { title: newTitle }
    try {
      const response = await postRequest(`/new-feed/update-feed-tab-title/${tabId}`, body, true)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getPromptList = createAsyncThunk<GetPromptListResponse>(
  'newFeedSlice/getPromptList',
  async (_, {rejectWithValue}) => {
    try {
      const response = await getRequest(`/prompt/list-for`, true)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const generateMessage = createAsyncThunk<GenerateMessageResponse, GenerateMessagePayload>(
  'newFeedSlice/generateMessage',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`/prompt/generate-comment`, payload, true)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const sendMessage = createAsyncThunk<SendMessageResponse, SendMessagePayload>(
  'newFeedSlice/sendMessage',
  async ({id, message, leadIdentity}, {rejectWithValue}) => {
    const body = {id, message, leadIdentity, companyUrn: 'self', likePost: false}
    try {
      const response = await postRequest(`/lead-information/send-comment`, body, true)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const deletePost = createAsyncThunk<DeletePostResponse, DeletePostPayload>(
  'newFeedSlice/deletePost',
  async ({postId}, {rejectWithValue}) => {
    try {
      const response = await deleteRequest(`/new-feed/delete-post/${postId}`, true)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
