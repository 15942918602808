import { Box, Typography } from '@mui/material';
import * as React from 'react';
import scss from '../automation_page.module.scss'
import { AutomationPageTimeZone } from './settings/automationPageTimeZonet';
import { AutomationPageSettingsWrapper } from './settings/automationPageSettingWrapper';
import { AutomationPageDelayBetweenComments } from './settings/automationPageDelayBetweenComments';
import { AutomationPageDelayBetweenAutomation } from './settings/automationPageDelayBetweenAutomation';
import { AutomationPageDays } from './settings/automationPageDays';
import { AutomationPageWorkingHours } from './settings/automationPageWorkingHours';
import { AutomationDailyCommentsQouta } from './settings/automationPageDailyCommentsQouta';

type Props = {
  handleChangeSlider: (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
    name: string
  ) => void;
}

export const AutomationPageSettings = ({ handleChangeSlider }: Props) => {
  return (
    <Box className={scss.setting}>
      <Typography className={scss.partMainTitle}>
        Automation settings:
      </Typography>

      <Box className={scss.setting__wrapper}>
        <AutomationPageSettingsWrapper
          title='Daily comments qouta'
          isBoxShadow
          width='490px'
        >
          <AutomationDailyCommentsQouta handleChangeSlider={handleChangeSlider} />
        </AutomationPageSettingsWrapper>

        <AutomationPageSettingsWrapper
          title='Set a time delay between the comments.'
          isBoxShadow
          width='490px'
        >
          <AutomationPageDelayBetweenComments handleChangeSlider={handleChangeSlider} />
        </AutomationPageSettingsWrapper>

        <AutomationPageSettingsWrapper
          title='Set an automation delay for the comments you’re sending.'
          isBoxShadow
          width='490px'
        >
          <AutomationPageDelayBetweenAutomation handleChangeSlider={handleChangeSlider} />
        </AutomationPageSettingsWrapper>

        <AutomationPageSettingsWrapper
          title='Time Zone.'
          isBoxShadow
          width='490px'
        >
          <AutomationPageTimeZone />
        </AutomationPageSettingsWrapper>

        <AutomationPageSettingsWrapper
          title='Workings day.'
          isBoxShadow
          width='490px'
        >
          <AutomationPageDays />
        </AutomationPageSettingsWrapper>

        <AutomationPageSettingsWrapper
          title='Workings hour.'
          isBoxShadow
          width='490px'
        >
          <AutomationPageWorkingHours handleChangeSlider={handleChangeSlider} />
        </AutomationPageSettingsWrapper>


      </Box>
    </Box >
  )
}

