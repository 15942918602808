import React, { useState } from 'react';
import styles from "./RenameTab.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { Box, styled } from '@mui/material';
import { MainActionButton } from '../../actionButton/MainActionButton';
import { useNewFeedData } from '../../../utils/redux/storeHooks/dataHooks/useNewFeedData';
import { useNewFeedActions } from '../../../utils/redux/storeHooks/actionsHooks/useNewFeedActions';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    height: '44px',
    borderRadius: '1px',
    fontFamily: 'Kanit',
    margin: 0,

    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(109, 48, 183, 1)',
      boxShadow: 'rgba(109, 48, 183, 1) 0px 1px 3px'
    },
    '& .MuiInputBase-input': {
      caretColor: 'rgba(109, 48, 183, 1)',
    },
  },
})

export const PopUpRenameTab = () => {
  const { tabs, deleteOrUpdateTabId, isUpdatingTitleTab } = useNewFeedData();
  const { updateTitleTab } = useNewFeedActions();
  const { setPopupRenameTabOpen } = usePopupActions();
  const initialTitleValue = tabs.find(tab => tab.id === deleteOrUpdateTabId)?.title || '';
  const [inputValue, setInputValue] = useState(initialTitleValue)

  const closePopUp = () => {
    setPopupRenameTabOpen(false)
  }

  const save = () => {
    updateTitleTab({
      tabId: deleteOrUpdateTabId,
      newTitle: inputValue,
      initialTitleValue
    })
  }

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.header__title}>
          Rename filter
        </Box>
        <IconButton
          color="default"
          onClick={closePopUp}
          className={styles.header__closeButton}
        >
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>
      <Box className={styles.content}>
        <StyledTextField
          fullWidth
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </Box>

      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={closePopUp}
          className={styles.footer__cancel}
        >
          Cancel
        </Button>
        <MainActionButton
          title='Save'
          height={40}
          width={66}
          fontWeight={300}
          actionOnClick={save}
          isLoading={isUpdatingTitleTab}
          isDisabled={isUpdatingTitleTab}
        />
      </Box>
    </Box>
  );
};
