import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(() => ({
  root: {
    // maxWidth: '224px',
    // padding: '12px 16px',
    // maxHeight: '64px',
  },
  sortedLabel: {
    '&.MuiTableSortLabel-active': {
      color: 'red', // Custom color for active sort label

      '&.MuiTableSortLabel-icon': {
        color: 'red !important'
      }
    },
    color: '#DB491F !important', // Custom color for sorted column name
  },
  sortedIcon: {
    color: '#DB491F !important',
  },
  tableRowRoot: {
    // "&$tableRowSelected, &$tableRowSelected:hover": {
    //   backgroundColor: '#E0E8F9'
    // }
  },
  tableRowSelected: {
    // backgroundColor: '#E0E8F9'
  },

  '&.MuiButtonBase-root .Mui-checked': {
    // color: '#E0E8F9'
  },
}));