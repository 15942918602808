import { useAppSelector } from '../typedHooks';

export const useAutomationData = () => {
  const {
    currentNumberOfReplyForToday,
    delayBetweenComments,
    delayAutomation,
    scrollStep,
    getAutomationDataloading,
    selectedTimeZone,
    selectedDays,
    workingHours,
    dailyCommentsQouta,
    isUpdating,
    leadsList,
    excludeLeadsList,
    automationTab,
    isRunAutomationRequest,
    isAutomationRunning,
    isGetLeadsToExcludeRequest,
    currentPage,
    hasMore,
    isShowOnlyExcluded,
    inputName,
    limitOfReplyForADay,
    useStaticPrompt
  } = useAppSelector((state) => state.automationReducer);

  return {
    currentNumberOfReplyForToday,
    delayBetweenComments,
    delayAutomation,
    scrollStep,
    getAutomationDataloading,
    selectedTimeZone,
    selectedDays,
    workingHours,
    dailyCommentsQouta,
    isUpdating,
    leadsList,
    excludeLeadsList,
    automationTab,
    isRunAutomationRequest,
    isAutomationRunning,
    isGetLeadsToExcludeRequest,
    currentPage,
    hasMore,
    isShowOnlyExcluded,
    inputName,
    limitOfReplyForADay,
    useStaticPrompt
  };
};