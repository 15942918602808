import * as React from 'react';
import scss from '../DashboardLeadsPeoplePage.module.scss';
import { Box, Button } from '@mui/material';
import { useTableLeadsActions } from '../../../utils/redux/storeHooks/actionsHooks/useTableLeadsActions';
import { useTableLeadsData } from '../../../utils/redux/storeHooks/dataHooks/useTableLeadsData';

export const SelectedIndicator = () => {
  const { setIsAllSelected, setSelected } = useTableLeadsActions();
  const { selected, isAllSelected, allLeadsCount, rowsPerPage, rows } = useTableLeadsData();

  const onAllSelected = () => {
    const newSelected = rows.map((n) => String(n.id));
    setSelected(newSelected);
    setIsAllSelected(true)
  }

  const onAllDeselected = () => {
    setSelected([])
    setIsAllSelected(false)
  }

  if (isAllSelected) {
    return (
      <Box className={scss.selectedIndicator}>
        {`All ${allLeadsCount} leads are selected.`}
        <Box className={scss.selectedBtnContainer}>

          <Button
            className={scss.selectAllBtn}
            variant='contained'
            onClick={onAllDeselected}
            sx={{
              boxShadow: 'none',
              ':hover': {
                boxShadow: 2
              }
            }}
          >
            Deselect
          </Button>
        </Box>
      </Box>
    )
  }

  if (rowsPerPage === selected.length) {
    return (
      <Box className={scss.selectedIndicator}>
        {`All ${rowsPerPage} rows on this page are selected.`}
        <Box className={scss.selectedBtnContainer}>

          <Button
            className={scss.selectAllBtn}
            variant='contained'
            onClick={onAllSelected}
            sx={{
              boxShadow: 'none',
              ':hover': {
                boxShadow: 2
              }
            }}
          >
            {`Select all ${allLeadsCount} items`}
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <Box className={scss.selectedIndicator}>
      {`${selected.length} of  ${rowsPerPage} selected`}
    </Box>
  )
}

