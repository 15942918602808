import React, { useEffect } from "react";
import { FunctionComponent } from "react";
import styles from "./TeamManagementPage.module.scss";
import { TeamManagementPageActions } from "./features/TeamManagementPageActions";
import { TableTeamManagment } from "../../components/tableTeamManagment/TableTeamManagment";
import { PagesTabs } from "../../components/pagesTabs/PagesTabs";
import { Box } from "@mui/material";
import { useTableTeamManagementActions } from "../../utils/redux/storeHooks/actionsHooks/useTableTeamManagementActions";
import { getUserInformationFromlocalStorage } from "../../utils/general/get_set_user_information";
import { pagesTabs } from "../../utils/pages/teamManagmentPage/tabs";
import { TabsType } from "../../types/general/pagesTabs";

export const TeamManagementPage: FunctionComponent = () => {
  const { getTeamList } = useTableTeamManagementActions()

  useEffect(() => {
    const userInformation = getUserInformationFromlocalStorage()
    getTeamList({ userInformation });
  }, [getTeamList]);

  return (
    <Box className={styles.pageContainer} sx={{}}>
      <PagesTabs tabs={pagesTabs} tabsType={TabsType.regular} />
      <TeamManagementPageActions />
      <TableTeamManagment />
    </Box>
  );
};
