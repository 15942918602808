import { Box } from '@mui/material';
import * as React from 'react';
import style from '../../botManagementPage.module.scss'


export const BotHeader = () => {
  return (
    <Box className={style.botManagement__botsHeader}>
      <Box
        sx={{ width: 48 }}
        className={style.botManagement__headerBlock}
      />
      <Box
        sx={{ width: 120 }}
        className={style.botManagement__headerBlock}
      >
        Name
      </Box>
      <Box
        sx={{ width: 70 }}
        className={style.botManagement__headerBlock}
      >
        Cookies
      </Box>
      <Box
        sx={{ width: 70 }}
        className={style.botManagement__headerBlock}
      >
        Busy
      </Box>
      <Box
        sx={{ width: 70 }}
        className={style.botManagement__headerBlock}
      >
        Premium
      </Box>
      <Box
        sx={{ width: 200 }}
        className={style.botManagement__headerBlock}
      >
        Work type
      </Box>
      <Box sx={{ width: 50 }} />
      <Box sx={{ width: 50 }} />
    </Box>
  )
}

