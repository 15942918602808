import React from 'react';
import styles from "../RunAutomation.module.scss";
import { Box, Button } from '@mui/material';
import { MainActionButton } from '../../../actionButton/MainActionButton';
import { useAutomationActions } from '../../../../utils/redux/storeHooks/actionsHooks/useAutomationActions';
import { useLocation } from 'react-router-dom';
import { useNewFeedData } from '../../../../utils/redux/storeHooks/dataHooks/useNewFeedData';
import { initialTab } from '../../../../utils/pages/newFeedPage/initialTab';
import { useAutomationData } from '../../../../utils/redux/storeHooks/dataHooks/useAutomationData';
import { useTableLeadsData } from '../../../../utils/redux/storeHooks/dataHooks/useTableLeadsData';
import { useSidebarData } from '../../../../utils/redux/storeHooks/dataHooks/useSidebarData';
import { useLeadsNotificationData } from '../../../../utils/redux/storeHooks/dataHooks/useLeadsNotificationData';

type Props = {
  closePopUp: () => void;
}

export const FooterPopUp = ({ closePopUp }: Props) => {
  const { runAutomation } = useAutomationActions();
  const { checkOfValidCookie, checkingCookiesProcess } = useSidebarData()
  const { isNotificationUpdating } = useLeadsNotificationData()
  const {
    isAutomationRunning,
    excludeLeadsList,
    isRunAutomationRequest
  } = useAutomationData();
  const { tabs } = useNewFeedData()
  const pathname = useLocation().pathname
  const tabId = pathname.split('/').pop();
  const { uploadingSnLink } = useTableLeadsData();

  const currentTab = tabs.find(tab => tab.id === tabId) || initialTab;

  const handleRunAutomation = () => {
    runAutomation({
      isAutomationRunning: isAutomationRunning ? false : true,
      tab: currentTab,
      excludeLeads: excludeLeadsList.map(lead => lead.leadIdentitySN),
    })
  }

  return (
    <Box className={styles.footer}>
      <Button
        variant='text'
        onClick={closePopUp}
        className={styles.footer__cancel}
      >
        Cancel
      </Button>
      <MainActionButton
        title='Run automation'
        actionOnClick={handleRunAutomation}
        isDisabled={isAutomationRunning || isRunAutomationRequest || uploadingSnLink?.isUploading || !checkOfValidCookie || checkingCookiesProcess || isNotificationUpdating}
        isLoading={isRunAutomationRequest}
      />
    </Box>
  )
}

