import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, styled } from '@mui/material';
import * as React from 'react';
import { useAutomationData } from '../../../../utils/redux/storeHooks/dataHooks/useAutomationData';
import { useAutomationActions } from '../../../../utils/redux/storeHooks/actionsHooks/useAutomationActions';

const CustomFormControl = styled(FormControl)({
  '& label.Mui-focused': {
    color: '#3B2470',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#3B2470',
    opacity: '0.7'
  },
});

const mainTimezones = ['America/New_York', 'Europe/London', 'Asia/Tokyo', 'Australia/Sydney'];

export const AutomationPageTimeZone = () => {
  const { selectedTimeZone } = useAutomationData();
  const { setSelectedTimeZone } = useAutomationActions();
  const handleChange = (event: SelectChangeEvent) => {
    setSelectedTimeZone(event.target.value as string);
  };

  return (
    <CustomFormControl fullWidth disabled>
      <InputLabel id="demo-simple-select-label">Time Zone</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedTimeZone}
        label="Time Zone"
        onChange={handleChange}
      >
        {mainTimezones.map((item, index) => (
          <MenuItem key={index} value={item}>{item}</MenuItem>
        ))}
      </Select>
    </CustomFormControl>
  )
}

