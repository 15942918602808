import React from 'react';
import styles from "./PopupSendMessage.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { usePopupData } from '../../../utils/redux/storeHooks/dataHooks/usePopupData';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { MainActionButton } from '../../actionButton/MainActionButton';
import { Avatar, Box, Typography } from '@mui/material';

export const PopupSendMessage = () => {
  const {
    setPopupSendMessageOpen,
    setIceBreakerMsg,
    setTextOfPost,
    sendInvitation,
    sendDM
  } = usePopupActions();
  const {
    isMessageGenerating,
    isMessageSending,
    iceBreakerMsg,
    leadConnectionPosition,
    leadId,
    profileImg,
    userFirstLastName,
    isHaveNewActivity
  } = usePopupData();

  const sendInvitationToLead = () => {
    sendInvitation({
      leadId,
      message: iceBreakerMsg,
      isLeadHaveNewActivity: isHaveNewActivity
    })
  }

  const sendDMToLead = () => {
    sendDM({
      leadId,
      message: iceBreakerMsg,
      isLeadHaveNewActivity: isHaveNewActivity
    })
  }

  const closePopupSendMessage = () => {
    setPopupSendMessageOpen(false);
    setIceBreakerMsg('')
    setTextOfPost('')
  };

  return (
    <>
      <div className={styles.popupBody}>
        <div className={styles.header}>
          <div className={styles.title}>
            {leadConnectionPosition === 1 ? 'Send DM'
              : 'Send a connection request & message'}
          </div>
          <IconButton aria-label="delete" color="default" onClick={closePopupSendMessage} className={styles.closeButton}>
            <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
          </IconButton>
        </div>
        <div className={styles.userInfo}>
          <Avatar
            alt=""
            src={profileImg}
            sx={{ width: '40px', height: '40px', marginRight: '8px' }}
          />
          <div>
            <Typography sx={{
              fontFamily: 'Inter',
              fontSize: '18px'
            }}>
              {userFirstLastName}
            </Typography>
            <Typography sx={{
              fontFamily: 'Inter',
              fontSize: '14px'
            }}>
              {leadConnectionPosition === 1 ? '1st connection'
                : leadConnectionPosition === 2 ? '2nd connection' :
                  '3rd connection'}
            </Typography>
          </div>
        </div>
        <div className={styles.messageToLead}>
          <div>Message to Lead</div>
          <div>
            <TextField
              value={iceBreakerMsg} onChange={(e) => { setIceBreakerMsg(e.target.value) }}
              placeholder=""
              multiline
              rows={6}
              fullWidth={true}
              maxRows={Infinity}
              InputProps={{
                endAdornment: isMessageGenerating && (
                  <Box sx={{
                    display: 'flex',
                    gap: '12px',
                    position: 'absolute',
                    bottom: '12px',
                    right: '12px'
                  }}>
                    <Typography sx={{
                      fontFamily: 'Inter',
                      fontSize: '12px'
                    }}>
                      Generating message
                    </Typography>
                    <CircularProgress
                      size={20}
                      sx={{ color: '#3B2470' }}
                    />
                  </Box>
                )
              }}
              sx={{
                borderColor: 'neutral/gray !important',
                borderRadius: 20,
              }}
              InputLabelProps={{
                sx: {
                  backgroundColor: 'red',
                  color: '#003566',
                  textTransform: 'capitalize',
                },
              }}
            />
          </div>

          <div className={styles.buttonGroup}>
            <div>
              <Button variant='text' onClick={closePopupSendMessage} className={styles.cancel}>Cancel</Button>
            </div>
            <div>
              <MainActionButton
                title={leadConnectionPosition === 1 ? 'Send' : 'Send Invitation'}
                actionOnClick={leadConnectionPosition === 1 ? sendDMToLead : sendInvitationToLead}
                isLoading={isMessageSending}
                isDisabled={isMessageSending}
              />
            </div>
          </div>
        </div>
      </div >
    </>
  );
};
