import { useMemo } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useAppDispatch } from '../typedHooks';
import { popupSliceActions } from '../../slices/popupSlice/popupSlice';
import { generateIceBreake, sendDM, sendInvitation, uploadCSV } from '../../slices/popupSlice/thunks';

const rootActions = {
  ...popupSliceActions,
  generateIceBreake,
  uploadCSV,
  sendInvitation,
  sendDM
};

export const usePopupActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch]);
};