import { Box } from "@mui/material";
import { motion } from "framer-motion";
import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
}

const animations = {
  hidden: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
}

export const AnimateTab = ({ children }: Props) => {
  return (
    <Box sx={{ width: '100%' }}>
      <motion.div
        variants={animations}
        initial='hidden'
        animate='animate'
        transition={{ ease: "linear" }}
      >
        {children}
      </motion.div>
    </Box>
  )
}

