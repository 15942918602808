import { Skeleton, TableBody, TableCell, TableRow } from '@mui/material';
import * as React from 'react';
import { TableLoader } from '../../tableLoader/TableLoader';
import { useTableImportCSVData } from '../../../utils/redux/storeHooks/dataHooks/useTableImportCSVData';

const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const LoaderSkeleton = () => {
  const { loading } = useTableImportCSVData()
  return (
    <TableBody sx={{ position: 'relative' }}>
      {loading && <TableLoader />}
      {arr.map((elem) => (
        <TableRow key={elem} sx={{ maxHeight: '64px' }}>
          <TableCell sx={{ width: '26px', padding: '12px 12px' }}>
            <Skeleton variant="rectangular" width='0' height={40} />
          </TableCell>
          <TableCell sx={{ minWidth: '100%', maxWidth: '100%', padding: '0px 12px' }}>
            <Skeleton variant="rectangular" width='0' height={40} />
          </TableCell>
          <TableCell sx={{ minWidth: '100%', maxWidth: '100%', padding: '0px 12px' }}>
            <Skeleton variant="rectangular" width='0' height={40} />
          </TableCell>
          <TableCell sx={{ minWidth: '100%', maxWidth: '100%', padding: '0px 12px' }}>
            <Skeleton variant="rectangular" width='0' height={40} />
          </TableCell>
          <TableCell sx={{ minWidth: '100%', maxWidth: '100%', padding: '0px 12px' }}>
            <Skeleton variant="rectangular" width='0' height={40} />
          </TableCell>
          <TableCell sx={{ minWidth: '100%', maxWidth: '100%', padding: '0px 12px' }}>
            <Skeleton variant="rectangular" width='0' height={40} />
          </TableCell>
        </TableRow>))}
    </TableBody>
  )
}