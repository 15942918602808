import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { useEffect, useRef } from 'react';
import { TableTeamManagmentHeader } from './features/TableTeamManagmentHeader';
import { TableTeamManagmentBody } from './features/TableTeamManagmentBody';
import { LoaderSkeleton } from './features/LoaderSkeleton';
import { useTableTeamManagementData } from '../../utils/redux/storeHooks/dataHooks/useTableTeamManagementData';

export const TableTeamManagment = () => {
  const { rows, loading, filteredTeamMembers } = useTableTeamManagementData();
  const tableRef = useRef(null);
  const tableContainerRef = useRef(null);

  // const handleScroll = () => {
  //   const tableContainer = tableContainerRef.current;
  //   if (tableRef.current) {
  //     const tableRect = tableRef.current.getBoundingClientRect();
  //     if (tableRect.top <= 20) {
  //       tableContainer.style.height = `calc(100vh - 40px)`
  //       return;
  //     }
  //     tableContainer.style.height = `100%`
  //   }
  // };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleScrollVerticaly = (event: any) => {
    if (filteredTeamMembers.length > 4) {
      return;
    } else {
      event.preventDefault();
      tableContainerRef.current.scrollLeft += event.deltaY;
    }

  }

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  useEffect(() => {
    const currentContainer = tableContainerRef.current;
    currentContainer.addEventListener("wheel", handleScrollVerticaly)
    return () => {
      currentContainer.removeEventListener("wheel", handleScrollVerticaly)
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTeamMembers]);

  return (
    <>
      <Box sx={{ width: '100%', }} >
        <Paper sx={{ width: '100%', boxShadow: 'unset' }}>
          <TableContainer ref={tableContainerRef} sx={{
            width: '100%',
            borderRadius: 2,
            scrollBehavior: 'smooth',
            height: 'calc(100vh - 240px)'
          }}
          >
            <Table
              ref={tableRef}
              sx={{ minWidth: 1100 }}
              aria-labelledby="tableTitle"
              size={'medium'}
              stickyHeader
            >
              <TableTeamManagmentHeader />
              {rows.length === 0 && loading && <LoaderSkeleton />}
              <TableTeamManagmentBody />
            </Table>
          </TableContainer>
        </Paper>
      </Box >
    </>
  );
}
