import React from 'react'
import { checkUserRole } from '../utils/general/get_set_user_information'
import { NotAllowedPage } from '../pages/notAllowedPage/NotAllowedPage';
import { AdminPage } from '../pages/adminPage/AdminPage';

export const AdminPageGuard = () => {
  const isAllowed = checkUserRole('Master')


  return isAllowed ? <AdminPage /> : <NotAllowedPage />;
}