import { Box } from '@mui/material';
import * as React from 'react';
import style from '../../botManagementPage.module.scss'
import moment from 'moment';
import { SnLink } from '../../../../types/entities/snLink';

type Props = {
  link: SnLink;
}
export const SnLinksCard = ({ link }: Props) => {
  const momentDate = moment(link.dateInsert);
  const formattedDateTime = momentDate.format('DD.MM.YY, hh:mm a');

  return (
    <Box className={style.botManagement__snLinks_card}>
      <Box className={style.botManagement__snLinks_card_id}>
        {link.id}
      </Box>
      <Box className={style.botManagement__snLinks_card_dateInsert}>
        {formattedDateTime}
      </Box>
      <Box className={style.botManagement__snLinks_card_uploadingStep}>
        {link.uploadingStep}
      </Box>
      <Box className={style.botManagement__snLinks_card_totalLeadsCount}>
        {link.totalLeadsCount}
      </Box>
      <Box className={style.botManagement__snLinks_card_uploadingErrorMessage}>
        {`${link.uploadingErrorMessage.slice(0, 100)}...`}
      </Box>
    </Box >
  )
}

