import { Box, Typography, Zoom } from '@mui/material';
import * as React from 'react';
import scss from '../automation_page.module.scss'
import { useAutomationData } from '../../../utils/redux/storeHooks/dataHooks/useAutomationData';
import { useAutomationActions } from '../../../utils/redux/storeHooks/actionsHooks/useAutomationActions';
import { MainActionButton } from '../../../components/actionButton/MainActionButton';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { useLeadsNotificationData } from '../../../utils/redux/storeHooks/dataHooks/useLeadsNotificationData';
import { TooltipApp } from '../../../components/tooltipApp/TooltipApp';



export const AutomationPageActions = () => {
  const { updateConfig, runAutomation } = useAutomationActions();
  const {
    delayBetweenComments,
    delayAutomation,
    selectedTimeZone,
    selectedDays,
    workingHours,
    dailyCommentsQouta,
    isUpdating,
    isAutomationRunning,
    isRunAutomationRequest,
    automationTab
  } = useAutomationData();
  const { setPopUpRunAutomation } = usePopupActions();
  const { isNotificationUpdating } = useLeadsNotificationData()


  const handleOpenPopUpRunAutomation = () => {
    setPopUpRunAutomation(true)
  }

  const handleStopAutomation = () => {
    runAutomation({
      isAutomationRunning: false,
      tab: automationTab,
      excludeLeads: []
    })
  }

  const handleUpdateConfig = () => {
    const body = {
      maxNumberOfReply: 0,
      minDelayBetweenReply: delayBetweenComments[0],
      maxDelayBetweenReply: delayBetweenComments[1],
      totalCountOfReply: 0,
      minDelayInHour: delayAutomation[0],
      maxDelayInHour: delayAutomation[1],
      isRunning: false,
      selectedTimeZone,
      selectedDays,
      workingHours,
      dailyCommentsQouta
    }
    updateConfig(body)
  };
  return (
    <Box className={scss.action}>
      <Typography className={scss.partMainTitle}>
        Automation actions:
      </Typography>
      <Box className={scss.action__actionButtonWrapper}>
        {
          isNotificationUpdating
            ? (
              <TooltipApp TransitionComponent={Zoom} placement="left-start" title={
                <>
                  <span className={scss.toolTipBody}>
                    Please wait while notifications update.
                  </span>
                </>
              }>
                <Box>
                  <MainActionButton
                    actionOnClick={isAutomationRunning ? handleStopAutomation : handleOpenPopUpRunAutomation}
                    isDisabled={isNotificationUpdating}
                    isLoading={isRunAutomationRequest || isAutomationRunning}
                    title={isAutomationRunning ? 'Stop automation' : 'Run automation'}
                    width={150}
                    height={43}
                  />
                </Box>
              </TooltipApp>
            )
            : (
              <MainActionButton
                actionOnClick={isAutomationRunning ? handleStopAutomation : handleOpenPopUpRunAutomation}
                isDisabled={isRunAutomationRequest || isNotificationUpdating}
                isLoading={isRunAutomationRequest || isAutomationRunning}
                title={isAutomationRunning ? 'Stop automation' : 'Run automation'}
                width={150}
                height={43}
              />
            )
        }
        <MainActionButton
          title='updateConfig'
          actionOnClick={handleUpdateConfig}
          isDisabled={isAutomationRunning || isRunAutomationRequest || isUpdating}
          isLoading={isUpdating}
          width={150}
          height={43}
        />
      </Box>
    </Box>
  )
}

