import React, { useEffect, useState } from 'react';
import styles from "./PopUpAddLeadUseSn.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { Box, InputAdornment, Typography, styled } from '@mui/material';
import { MainActionButton } from '../../actionButton/MainActionButton';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { getUserInformationFromlocalStorage } from '../../../utils/general/get_set_user_information';
import { useTableLeadsActions } from '../../../utils/redux/storeHooks/actionsHooks/useTableLeadsActions';
import { TeamListFlow } from './features/TeamListFlow';
import { useBotManagementActions } from '../../../utils/redux/storeHooks/actionsHooks/botManagementActions';
import UserInformation from '../../../types/entities/user_information';
import { useBotManagementData } from '../../../utils/redux/storeHooks/dataHooks/useBotManagementData';
import Cookies from 'universal-cookie';
import { useAutomationData } from '../../../utils/redux/storeHooks/dataHooks/useAutomationData';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    height: '44px',
    borderRadius: '1px',
    fontFamily: 'Kanit',
    margin: 0,

    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(109, 48, 183, 1)',
      boxShadow: 'rgba(109, 48, 183, 1) 0px 1px 3px'
    },
    '& .MuiInputBase-input': {
      caretColor: 'rgba(109, 48, 183, 1)',
    },
  },
})

export const PopUpAddLeadUseSn = () => {
  const { setPopUpAddLeadsUseSnOpen } = usePopupActions();
  const { getListOfTeamMembersAndCheckCookies } = useTableLeadsActions();
  const { loadLeadsSnGet } = useBotManagementActions();
  const { isLoadingRunLoadLeadsSnGet } = useBotManagementData()
  const {
    isAutomationRunning,
  } = useAutomationData();
  const [inputValue, setInputValue] = useState('')
  const [selectUserForParse, setSelectUserForParse] = useState<UserInformation | null>(null);
  const organizationId = getUserInformationFromlocalStorage().organizationId
  const closePopUp = () => {
    setPopUpAddLeadsUseSnOpen(false)
  }

  const onSelectUserHandler = (user: UserInformation) => {
    if (selectUserForParse?.uidd === user.uidd) {
      setSelectUserForParse(null)
      return;
    }
    setSelectUserForParse(user)
  }

  const addSnLink = () => {
    if (selectUserForParse && inputValue) {
      const userUuid = new Cookies().get('ht');
      loadLeadsSnGet({
        botUuid: selectUserForParse.uidd,
        userUuid,
        snLink: inputValue,
      })
    }

  }

  useEffect(() => {
    const userInformation = getUserInformationFromlocalStorage()
    getListOfTeamMembersAndCheckCookies({ userInformation });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId])

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.header__title}>
          Add leads from Sales Navigator
        </Box>
        <IconButton
          color="default"
          onClick={closePopUp}
          className={styles.header__closeButton}
        >
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>
      <Box className={styles.content}>
        <Typography sx={{ color: '#6F7D9B', fontFamily: 'Kanit', fontSize: 16, marginBottom: '8px' }}>
          Link
        </Typography>
        <StyledTextField
          fullWidth
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <InsertLinkIcon />
              </InputAdornment>
            )
          }}
        />
        <TeamListFlow
          inputValue={inputValue}
          onSelectUserHandler={onSelectUserHandler}
          selectUserForParse={selectUserForParse}
        />
      </Box>

      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={closePopUp}
          className={styles.footer__cancel}
        >
          Cancel
        </Button>
        <MainActionButton
          title='Add'
          height={40}
          width={66}
          fontWeight={300}
          actionOnClick={addSnLink}
          isLoading={isLoadingRunLoadLeadsSnGet}
          isDisabled={isLoadingRunLoadLeadsSnGet
            || !inputValue
            || !selectUserForParse
            || isAutomationRunning
            || !selectUserForParse.isCookieValidForNow
          }
        />
      </Box>
    </Box>
  );
};
