// Utility function to convert seconds to minutes
import { DateTime } from 'luxon';

// Utility function to convert seconds to minutes
export function convertPureSecondsToTime(seconds) {
    const currentDate = new Date();
    const da = new Date(parseInt(seconds))
    let differenceInMilliseconds = da.getTime() - currentDate.getTime();
    differenceInMilliseconds = Math.abs(differenceInMilliseconds);
    const secondstemp = Math.floor(differenceInMilliseconds / 1000);
    const minutes = Math.floor(secondstemp / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    const caseToShowMonths = months !== 0 ? `${months} months ago` : 0;
    const caseToShowDays = days !== 0 ? `${days} days ago` : 0;
    const caseToShowHours = hours !== 0 ? `${hours} hours ago` : 0;
    const caseToShowMinutes = minutes !== 0 ? `${minutes} minutes ago` : 0;

    const timeString = caseToShowMonths !== 0
        ? caseToShowMonths
        : caseToShowDays !== 0
            ? caseToShowDays
            : caseToShowHours !== 0
                ? caseToShowHours
                : caseToShowMinutes !== 0
                    ? caseToShowMinutes
                    : 'undefined'

    return timeString;
}

export function timeDifference(convertedTime, dateOfFeedInsert) {

    const feedTime = DateTime.fromISO(dateOfFeedInsert, { zone: "utc" });
    const now = DateTime.local();
    const milliseconds3 = 0
    console.log('process.env.REACT_APP_ENV :', process.env.REACT_APP_ENV)
    // if (process.env.REACT_APP_ENV === 'dev') {
    //     const hours = 3;
    //     milliseconds3 = hours * 3600000;
    // }

    // console.log('milliseconds3:', milliseconds3);
    // console.log('now.toMillis():                        ', now.toMillis())
    // console.log('feedTime.toMillis():                   ', feedTime.toMillis())
    const nowMil = now.toMillis();
    const feedMil = feedTime.toMillis();
    const sum = milliseconds3 + nowMil;
    const sim = sum - feedMil

    //console.log('secondsDifferenceTEST :', secondsDifferenceTEST)

    const secondsDifference = Math.floor((sim) / 1000);

    //console.log('secondsDifference :', secondsDifference)
    const positiveNumber = Math.abs(secondsDifference);
    // Define time units
    const units = [
        { label: 'months', duration: 30 * 24 * 60 * 60 }, // Approximation
        { label: 'days', duration: 24 * 60 * 60 },
        { label: 'hours', duration: 60 * 60 },
        { label: 'minutes', duration: 60 },
    ];

    // Find the largest non-zero time unit
    for (const unit of units) {
        const count = Math.floor(positiveNumber / unit.duration);
        if (count > 0) {
            return `about ${count} ${unit.label} ago`;
        }
    }

    // If the difference is less than a minute, show "just now"
    return 'just now';
}