import { Box, Typography } from '@mui/material';
import * as React from 'react';
import style from '../../newFeedPage.module.scss'
import { PostActions } from './PostActions';
import moment from 'moment';

type Props = {
  firstName: string;
  lastName: string;
  additionalInfo: string;
  postedDate: string;
  postText: string;
  activity: string;
  postId: number;
  userIdentity: string;
  isHidden: boolean;
}

export const PostContent = ({
  firstName,
  lastName,
  additionalInfo,
  postedDate,
  postText,
  activity,
  postId,
  userIdentity,
  isHidden
}: Props) => {
  let dateString: string = postedDate;
  const postMoment = moment.utc(dateString);
  const today = moment.utc().startOf('day');
  const yesterday = moment.utc().subtract(1, 'days').startOf('day');

  if (postMoment.isSame(today, 'd')) {
    dateString = 'today';
  } else if (postMoment.isSame(yesterday, 'd')) {
    dateString = 'yesterday';
  } else {
    dateString = postMoment.format('YYYY-MM-DD');
  }

  return (
    <Box>
      <Typography className={style.post__contentName}>{firstName} {lastName}</Typography>
      <Typography className={style.post__contentAddInfo}>{additionalInfo}</Typography>
      <Typography className={style.post__contentDate}>{dateString}</Typography>
      <Typography className={style.post__contentText}>{`${postText.slice(0, 100)}...`}</Typography>
      <PostActions
        activity={activity}
        postId={postId}
        userIdentity={userIdentity}
        isHidden={isHidden}
      />
    </Box>
  )
}