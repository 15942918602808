import { Box, Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { usePopupActions } from '../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { useLocation } from 'react-router-dom';
import { useTableImportCSVActions } from '../../utils/redux/storeHooks/actionsHooks/useTableImportCSVActions';
import { useTableImportCSVData } from '../../utils/redux/storeHooks/dataHooks/useTableImportCSVData';
import { getVariantButton } from '../../utils/components/uploadingCSVIndicator/getVariantButton';
import { excludePathName } from '../../utils/components/uploadingCSVIndicator/excludePaths';
import { ButtonVariant } from '../../types/components/uploadingCSVIndicator/buttonVariant';

export const UploadingCSVIndicator = () => {
  const [isComponentShown, setIsComponentShown] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [buttonVariant, setButtonVariant] = useState<ButtonVariant>()
  const { setPopupImportCSVOpen, setImportProcessStep } = usePopupActions();
  const { getImportsList } = useTableImportCSVActions();
  const { uploadingCSV } = useTableImportCSVData();
  const pathname = useLocation().pathname

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const openPopUp = () => {
    setImportProcessStep('uploadingProcess')
    setPopupImportCSVOpen(true)
  }

  useEffect(() => {
    // if (excludePathName.includes(pathname)) {
    //   setIsComponentShown(false)
    // } else {
    //   setIsComponentShown(true)
    //   getImportsList();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const buttonVariant = getVariantButton(uploadingCSV?.status || '')
    setButtonVariant(buttonVariant)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadingCSV])

  if (isComponentShown && uploadingCSV) {
    return (
      <Button
        variant="contained"
        sx={{
          backgroundColor: buttonVariant.backgroundColor,
          color: buttonVariant.color,
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          padding: '8px 12px',
          fontFamily: 'Kanit',
          boxShadow: 'none',
          borderRadius: '1px',
          '&:hover': {
            backgroundColor: buttonVariant.backgroundColor,
          }
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={openPopUp}
      >
        {isHovered && <CircularProgress color='inherit' size={22} />}
        {buttonVariant.text}
      </Button>
    )
  }

  return (
    <Box sx={{ display: 'none' }}></Box>
  )
}

