import axios from "axios";
import React, { useState } from "react";
import { FunctionComponent } from "react";
import scss from './restore_password.module.scss'
import { Button, IconButton, InputAdornment, TextField, styled, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import setUserInformationTolocalStorage from "../../../utils/general/get_set_user_information";

const CssTextField = styled(TextField)({
    '.MuiInputBase-input:-internal-autofill-selected': {
        backgroundColor: 'red',
    },
    '& label': {
        color: '#FFFFFF',
    },
    '& label.Mui-focused': {
        color: '#FFFFFF',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#FFFFFF',
    },
    '& .MuiOutlinedInput-root': {
        height: '40px',
        width: '400px',
        '& fieldset': {
            borderColor: '#FFFFFF',
            //height: '40px',
            borderRadius: '2px'
        },
        '&:hover fieldset': {
            borderColor: '#FFFFFF',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#FFFFFF',
        },
        borderRadius: '2px',
    },
    '& .MuiOutlinedInput-input': {
        padding: '12px 14px', // adjust the space around the input text
        color: '#FFFFFF'
    },
    '& .MuiInputBase-input': {
        height: '40px', // set the height value as per your requirement
    },
});

const RestorePassword: FunctionComponent = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [badSignIn, setBadSignIn] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const tokenPath = searchParams.get('token') || '';

    const handlePasswordChange = (event: any) => {
        setPassword(event.target.value);
        setIsPasswordValid(true);
    };

    const handleFormSubmit = (event: any) => {
        event.preventDefault();
        const body = {
            token: tokenPath,
            email: localStorage.getItem('email'),
            password: password,
        }

        if (!isValidPassword(password)) {
            setIsPasswordValid(false);
            return;
        }

        if (tokenPath) {
            axios.post(`${process.env.REACT_APP_API_URL}/auth/restore-pass-confirm`, body).then(res => {
                setUserInformationTolocalStorage(res.data, true);
                window.location.href = '/leads'
            }).catch(e => {
                setBadSignIn(e.response.data.message)
                console.log(e)
            })
        }
    };

    const isValidPassword = (password: string) => {
        const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
        return passwordPattern.test(password);
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    // async function checkIfEmailIsConfirmed(tokenPath: string) {
    //     console.log('tokenPath', tokenPath)
    //     if (tokenPath) {
    //         await axios.post(process.env.REACT_APP_API_URL + '/auth/restore-pass-confirm', {
    //             token: tokenPath
    //         }).then(res => {
    //             console.log(res)
    //             setUserInformationTolocalStorage(res.data);
    //             return res.data;
    //         }).catch(e => {
    //             console.log(e.response.data.message)
    //         })
    //     }
    // }

    return (
        <div className={scss.mainContainer}>
            <div className={scss.leftSide}>
                <div className={scss.contentWrapper}>
                    <div>
                        <section className={scss.logoSection}>
                            <img className={scss.logoPadding} src={'./registrationLogo.svg'} alt="" />
                            <img src={'./registrationText.svg'} alt="" />
                        </section>

                        <section className={scss.additionalInfo}>
                            Please enter new password:
                        </section>
                    </div>
                    <div>
                        <form onSubmit={handleFormSubmit}>
                            <div className={scss.actionBtns}>

                                {/* <CssTextField
                                    label="Email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    error={!isEmailValid || badSignIn !== ''}
                                    helperText={!isEmailValid
                                        ? 'Please enter a valid email address'
                                        : badSignIn !== ''
                                            ? badSignIn
                                            : ''}
                                /> */}
                                {/* 
                                <div className={scss.labelWithInputsWrapper}>
                                    <label className={scss.inputLabels}>Email Address</label>
                                    <CssTextField
                                        // label="Email"
                                        value={email}
                                        onChange={handlePasswordChange}
                                        error={!isEmailValid || badSignIn !== ''}
                                        helperText={!isEmailValid
                                            ? 'Please enter a valid email address'
                                            : badSignIn !== ''
                                                ? badSignIn
                                                : ''}
                                    />
                                </div> */}
                                <div className={scss.labelWithInputsWrapper}>
                                    <label className={scss.inputLabels}>Enter new password</label>
                                    <CssTextField
                                        type={showPassword ? "text" : "password"}
                                        value={password}
                                        onChange={handlePasswordChange}
                                        error={!isPasswordValid}
                                        helperText={!isPasswordValid
                                            ? <>
                                                <Typography variant="body2">
                                                    Password should have: minimum six characters
                                                </Typography>
                                                <Typography variant="body2">
                                                    at least one letter and one number
                                                </Typography>
                                            </>
                                            : ''}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleTogglePasswordVisibility}>
                                                        {showPassword
                                                            ? <Visibility sx={{ color: '#FFFFFF' }} />
                                                            : <VisibilityOff sx={{ color: '#FFFFFF' }} />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </div>
                                <Button variant="contained" type="submit" className={scss.signUp} disabled={!tokenPath}>
                                    Restore
                                </Button>
                            </div>
                        </form>
                    </div>
                    <footer className={scss.footerInfo}>
                        <label>
                            <p>OpenAI-powered app</p>
                        </label>
                    </footer>
                </div>
            </div >


            <div className={scss.rightSide}>
                <img src={'./banner.png'} alt="" />
            </div>
            {/* <div>
                    {login && (
                        <Navigate to="/" replace={true} />
                    )}
                </div> */}
        </div >
    );
}
export default RestorePassword;
