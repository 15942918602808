import * as React from 'react';
import scss from '../automation_page.module.scss'
import { Box, Typography } from '@mui/material';
import { useAutomationData } from '../../../utils/redux/storeHooks/dataHooks/useAutomationData';

export const AutomationPageCommentsLeft = () => {
  const { currentNumberOfReplyForToday, limitOfReplyForADay } = useAutomationData();

  return (
    <Box className={scss.messageCounter}>
      <Typography className={scss.partMainTitle}>
        Comments left:
      </Typography>
      <Box className={scss.messageCounter__counterContainer}>
        <Box className={scss.messageCounter__title}>
          Number of comments left for today:
        </Box>
        <Box className={scss.messageCounter__count}>
          <Box className={scss.messageCounter__commentsLeft}>
            {currentNumberOfReplyForToday}
          </Box>
          <Box className={scss.messageCounter__commentsSum}>
            {`/${limitOfReplyForADay}`}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

