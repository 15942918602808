import * as React from 'react';
import style from '../../botManagementPage.module.scss'
import { useState } from 'react';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { useBotManagementActions } from '../../../../utils/redux/storeHooks/actionsHooks/botManagementActions';
import { useBotManagementData } from '../../../../utils/redux/storeHooks/dataHooks/useBotManagementData';
import { SnLink } from '../../../../types/entities/snLink';

export const SelectLink = () => {
  const [open, setOpen] = useState(false);
  const { setSelectedSnLinkToInitialParse } = useBotManagementActions();
  const { snLinks } = useBotManagementData();

  const onChangeHandler = (event: React.SyntheticEvent<Element, Event>, newValue: SnLink) => {
    setSelectedSnLinkToInitialParse(newValue)
  }

  return (
    <Box className={style.botManagement__actions_selectUserContainer}>
      <Typography className={style.botManagement__actions_actionTitle}>
        Select SN link
      </Typography>
      <Autocomplete
        id="asynchronous-demo"
        sx={{ width: '300px' }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        className={style.botManagement__actions_autocomplete}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(snLinks) => `${snLinks.id}`}
        options={snLinks}
        onChange={(event, newValue) => onChangeHandler(event, newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select sn link"
          />
        )}
      />
      <Typography sx={{ fontSize: 12, alignSelf: 'flex-start', marginTop: 1, fontWeight: 300, color: 'red' }}>
        *Required
      </Typography>
    </Box>
  )
}

