import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { useEffect, useRef } from 'react';
import { TableImportCSVHeader } from './features/TableImportCSVHeader';
import { TableImportCSVBody } from './features/TableImportCSVBody';
import { LoaderSkeleton } from './features/LoaderSkeleton';
import { useTableImportCSVData } from '../../utils/redux/storeHooks/dataHooks/useTableImportCSVData';
import { AnimateTab } from '../animateTab/AnimateTab';

export const TableImportCSV = () => {
  const { rows, loading } = useTableImportCSVData();
  const tableRef = useRef(null);
  const tableContainerRef = useRef(null);

  const handleScroll = () => {
    const tableContainer = tableContainerRef.current;
    if (tableRef.current) {
      const tableRect = tableRef.current.getBoundingClientRect();
      if (tableRect.top <= 20) {
        tableContainer.style.height = `calc(100vh - 40px)`
        return;
      }
      tableContainer.style.height = `100%`
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <AnimateTab>
      <Box sx={{ width: '100%', }} >
        <Box sx={{ width: '100%', }} >
          <Paper sx={{ width: '100%', boxShadow: 'unset' }}>
            <TableContainer ref={tableContainerRef} sx={{
              width: '100%',
              borderRadius: 2,
              scrollBehavior: 'smooth',
            }}
            >
              <Table
                ref={tableRef}
                aria-labelledby="tableTitle"
                size={'medium'}
                stickyHeader
              >
                <TableImportCSVHeader />
                {rows.length === 0 && loading && <LoaderSkeleton />}
                <TableImportCSVBody />
              </Table>
            </TableContainer>
          </Paper>
        </Box >
      </Box>
    </AnimateTab>
  );
}
