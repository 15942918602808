import * as React from 'react';
import style from '../../newFeedPage.module.scss'
import { KeyWordInput } from './KeyWordInput';
import { KeywordInputsType } from '../../../../types/pages/newFeedPage/keyWordInput';
import { Box } from '@mui/material';
import { SelectLastDays } from './SelectLastDays';
import { ActionButtons } from './ActionButtons';

export const Actions = () => {
  return (
    <Box className={style.actions}>
      <Box className={style.actions__autocompleteWrapper}>
        <SelectLastDays />
        <Box className={style.actions__keywordWrapper}>
          <KeyWordInput title='Include Keywords' keyWordInputType={KeywordInputsType.include} />
          <KeyWordInput title='Exclude Keywords' keyWordInputType={KeywordInputsType.exclude} />
        </Box>
      </Box>
      <ActionButtons />
    </Box>
  )
}

