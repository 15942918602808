import { useMemo } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useAppDispatch } from '../typedHooks';
import { deleteCSVAndUpdateList, getImportsList, tableImportCSVActions } from '../../slices/tableImportCSVSlice/tableImportCSVSlice';

const rootActions = {
  ...tableImportCSVActions,
  getImportsList,
  deleteCSVAndUpdateList,
};

export const useTableImportCSVActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch]);
};