import { Box, Button } from '@mui/material';
import * as React from 'react';
import scss from './BulkDeleteButton.module.scss';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { useLocation } from 'react-router-dom';
import { useTableLeadsData } from '../../utils/redux/storeHooks/dataHooks/useTableLeadsData';
import { useTableLeadsActions } from '../../utils/redux/storeHooks/actionsHooks/useTableLeadsActions';
import { useTableImportCSVActions } from '../../utils/redux/storeHooks/actionsHooks/useTableImportCSVActions';
import { useTableImportCSVData } from '../../utils/redux/storeHooks/dataHooks/useTableImportCSVData';
import { RouterPaths } from '../../types/general/routerPaths';
import { getPageFromTo } from '../../utils/general/getPageFromTo';

export const BulkDeleteButton = () => {
  const pathname = useLocation().pathname;
  const { deleteLeadsAndUpdatedLeadList } = useTableLeadsActions();
  const { deleteCSVAndUpdateList } = useTableImportCSVActions();
  const { selectedImports } = useTableImportCSVData();
  const {
    selected,
    leadToSearch,
    page,
    rowsPerPage,
    order,
    orderBy,
    dateInserted,
    dateLastCommented,
    dateLastReplied,
    filterLeadName,
    filterCompanyName,
    selectedLeadConnectionPossition,
    isHaveNewActivitySelect,
    isAllSelected
  } = useTableLeadsData()

  const onDelete = () => {
    switch (pathname) {
      case RouterPaths.leads:
        DeleteListOfLeads();
        return;
      case RouterPaths.importCsv:
        DeleteListOfImports()
        return;
    }
  }

  const DeleteListOfLeads = () => {
    const { pageFrom, pageTo } = getPageFromTo(page, rowsPerPage);
    deleteLeadsAndUpdatedLeadList({
      selectedRows: selected,
      from: pageFrom,
      to: pageTo,
      order,
      orderBy,
      filters: {
        dateInserted,
        dateLastCommented,
        dateLastReplied,
        filterLeadName: filterLeadName || leadToSearch,
        filterCompanyName,
        selectedLeadConnectionPossition,
        isHaveNewActivitySelect
      },
      isAllSelected
    })
  };

  const DeleteListOfImports = async () => {
    deleteCSVAndUpdateList({ selectedImports: selectedImports })
  };

  return (
    <Box className={scss.deleteBtnContainer}>
      <Button
        className={scss.deleteBtn}
        variant='contained'
        onClick={onDelete}
        sx={{
          boxShadow: 'none',
          ':hover': {
            boxShadow: 2
          }
        }}
        startIcon={<DeleteOutlineRoundedIcon sx={{ width: 28, height: 28, fill: '#191F2E' }} />}
      >
        Remove
      </Button>
    </Box>
  )
}

