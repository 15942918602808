import { Box } from '@mui/material';
import React from 'react';
import styles from "../RunAutomation.module.scss";
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

type Props = {
  closePopUp: () => void;
}

export const HeaderPopUp = ({ closePopUp }: Props) => {

  return (
    <Box className={styles.header}>
      <Box className={styles.header__title}>
        Run automation
      </Box>
      <IconButton
        color="default"
        onClick={closePopUp}
        className={styles.header__closeButton}
      >
        <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
      </IconButton>
    </Box>
  )
}

