import { Avatar, Box, Button, Typography } from '@mui/material';
import * as React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import style from '../../botManagementPage.module.scss'
import { Bot } from '../../../../types/pages/botManagmentPage/bot';
import { useLocation } from 'react-router-dom';

type Props = {
  workerList: Bot[];
  removeFromWorkList: (bot: Bot) => void;
}

export const WorkerList = ({ workerList, removeFromWorkList }: Props) => {
  const pathname = useLocation().pathname;
  if (workerList.length === 0) {
    return (
      <Box className={style.botManagement__actions_workList} boxShadow={1} borderRadius={2}>
        <Typography className={style.botManagement__actions_actionTitle}>
          {'Workers list' + (pathname === '/bot-management/leads' ? ' (only one premium)' : '') + ':'}
        </Typography>
        <Typography sx={{ fontFamily: 'Kanit', fontWeight: 300 }}>
          No selected workers.
        </Typography>
      </Box>
    )
  }
  return (
    <Box className={style.botManagement__actions_workList} boxShadow={1} borderRadius={2}>
      <Typography className={style.botManagement__actions_actionTitle}>
        Workers list:
      </Typography>
      {workerList.map(bot => (
        <Box key={bot.uidd} className={style.botManagement__actions_workListItem}>
          <Avatar
            alt=""
            src={bot.profileImg}
            className={style.botManagement__actions_workListImg}
            sx={{ width: '48px', height: '48px' }}
          />
          <Typography className={style.botManagement__actions_workListName}>
            {`${bot.firstName} ${bot.lastName}`}
          </Typography>
          <Button
            className={style.botManagement__bots_deleteBot}
            onClick={() => removeFromWorkList(bot)}
          >
            <ClearIcon />
          </Button>
        </Box>
      ))}
    </Box>
  )
}

