import { Box, Typography } from '@mui/material';
import * as React from 'react';
import style from '../../botManagementPage.module.scss'
import { BotChart } from './botChart';
import { ChartName } from '../../../../types/pages/botManagmentPage/chartName';


export const BotStatisticCharts = () => {

  return (
    <Box className={style.botManagement__statistic_charts}>
      <BotChart
        chartTitle='Parsing feed for Today'
        chartName={ChartName.countOfLeadsProcessedByParsingFeedForToday}
      />
      <BotChart
        chartTitle='Parsing feed for this Month'
        chartName={ChartName.countOfLeadsProcessedByParsingFeedForMonth}
      />
      <BotChart
        chartTitle='Parsing leads for Today'
        chartName={ChartName.countOfLeadsProcessedByParsingLeadsForToday}
      />
      <BotChart
        chartTitle='Parsing leads for this Month'
        chartName={ChartName.countOfLeadsProcessedByParsingLeadsForMonth}
      />
    </Box>

  )
}

