import * as React from 'react';
import scss from '../AdminPage.module.scss';
import { Autocomplete, Box, InputAdornment, styled, TextField } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { RouterPaths } from '../../../types/general/routerPaths';
import { MainActionButton } from '../../../components/actionButton/MainActionButton';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { useAdminPageData } from '../../../utils/redux/storeHooks/dataHooks/useAdminPageData';
import { useAdminPageActions } from '../../../utils/redux/storeHooks/actionsHooks/useAdminPageActions';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';


const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiOutlinedInput-root': {
    padding: '2px 12px',
    borderRadius: '1px',
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(109, 48, 183, 1)',
      boxShadow: 'rgba(109, 48, 183, 1) 0px 1px 3px'
    },
  },
})

export const AdminPageActions = () => {
  const pathname = useLocation().pathname;
  const { users } = useAdminPageData();
  const { setfilteredUsers } = useAdminPageActions();
  const { setPopupSendExtensionOpen, setPopupSendCredentialOpen, setPopupCreateTeamOpen } = usePopupActions();

  const openPopupSendExtension = () => {
    setPopupSendExtensionOpen(true)
  };

  const openPopupSendCredentials = () => {
    setPopupSendCredentialOpen(true)
  };

  const onOpenPopUpCreateTeam = () => {
    setPopupCreateTeamOpen(true)
  }

  const [input, setInput] = useState<string>('')

  const filterUsers = async (member: string) => {
    if (!member) {
      setfilteredUsers(users);
      return;
    }

    const filtredTeam = users.filter((user) => user.firstName === member) || [];
    setfilteredUsers(filtredTeam);
  }

  if (pathname === RouterPaths.adminPageUsers) {
    return (
      <Box className={scss.actionsContainerMain}>
        <StyledAutocomplete
          sx={{ width: '300px' }}
          onInputChange={(_, newInputValue) => {
            setInput(newInputValue)
          }}
          onChange={(_, newValue: string | null) => {
            filterUsers(newValue);
          }}
          options={
            (users === null || users.length === 0)
              ? []
              : Array.from(new Set(users.map((user) => user.firstName)))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder='Search'
              value={input}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <MainActionButton
          title='Send Extension'
          actionOnClick={openPopupSendExtension}
        />
        <MainActionButton
          title='Send Credentials'
          actionOnClick={openPopupSendCredentials}
        />
      </Box>
    )
  }

  if (pathname === RouterPaths.adminPageTeams) {
    return (
      <Box className={scss.actionsContainerMain}>
        <MainActionButton
          title='Create team'
          actionOnClick={onOpenPopUpCreateTeam}
        />
      </Box>
    )
  }

  return <Box className={scss.buttonsSearch} />
}