import { HeadCell } from "../../../types/pages/dashboardLeadsPeoplePage/headCell";

export const headCellsUsers: readonly HeadCell[] = [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    disableSorting: false,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 150,
    minWidth: 150,
  },
  {
    id: 'teamName',
    numeric: false,
    disablePadding: false,
    label: 'Team',
    disableSorting: false,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 50,
    minWidth: 50,
  },
  {
    id: 'roles',
    numeric: true,
    disablePadding: false,
    label: 'Roles',
    disableSorting: true,
    showToolTip: true,
    textForToolTip: '',
    maxWidth: 100,
    minWidth: 100,
  },
  {
    id: 'leadsLimit',
    numeric: true,
    disablePadding: false,
    label: 'Leads limit',
    disableSorting: true,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 50,
    minWidth: 50,
  },
  {
    id: 'leadsLeft',
    numeric: true,
    disablePadding: false,
    label: 'Leads left',
    disableSorting: true,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 50,
    minWidth: 50,
  },
  {
    id: 'leadsCount',
    numeric: true,
    disablePadding: false,
    label: 'Leads count',
    disableSorting: true,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 50,
    minWidth: 50,
  },
  {
    id: 'update',
    numeric: true,
    disablePadding: false,
    label: '',
    disableSorting: true,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 50,
    minWidth: 50,
  },
  {
    id: 'Delete',
    numeric: true,
    disablePadding: false,
    label: '',
    disableSorting: true,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 50,
    minWidth: 50,
  },
  {
    id: 'Fix',
    numeric: true,
    disablePadding: false,
    label: '',
    disableSorting: true,
    showToolTip: false,
    textForToolTip: '',
    maxWidth: 50,
    minWidth: 50,
  }
];