import * as React from 'react';
import scss from '../DashboardLeadsPeoplePage.module.scss';
import { Avatar, Badge, Button } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useTableLeadsActions } from '../../../utils/redux/storeHooks/actionsHooks/useTableLeadsActions';
import { useTableLeadsData } from '../../../utils/redux/storeHooks/dataHooks/useTableLeadsData';
import { useSidebarActions } from '../../../utils/redux/storeHooks/actionsHooks/useSidebarActions';
import { useSidebarData } from '../../../utils/redux/storeHooks/dataHooks/useSidebarData';



export const FilterButton = () => {
  const { setIsFilterOpen } = useTableLeadsActions()
  const { isFilterOpen, appliedFilersCount } = useTableLeadsData()
  const { setSideBarWide } = useSidebarActions();
  const { isSideBarWide } = useSidebarData();

  const toogleFilter = () => {
    setIsFilterOpen(!isFilterOpen)
    if (isSideBarWide && !isFilterOpen) {
      setSideBarWide(false)
    }
  }

  return (
    <Button
      className={scss.filterButton}
      variant="contained"
      sx={{
        height: 40,
        "&:hover": { backgroundColor: '#fff', boxShadow: 3 },
      }}
      onClick={toogleFilter}
    >
      {appliedFilersCount
        ? (
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            badgeContent={
              <Avatar
                alt=""
                src="/Ellipse_red.svg"
                variant="rounded"
                sx={{ width: '8px', height: '8px' }}
              />
            }
          >
            <FilterListIcon sx={{ fontSize: '18px' }} />
          </Badge>
        )
        : (
          <FilterListIcon sx={{ fontSize: '18px' }} />
        )}
      {appliedFilersCount ? `Filter (${appliedFilersCount})` : 'Filter'}
    </Button>
  )
}

