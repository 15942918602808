import * as React from 'react';
import scss from '../DashboardLeadsPeoplePage.module.scss';
import { useTableLeadsData } from '../../../utils/redux/storeHooks/dataHooks/useTableLeadsData';
import { Box } from '@mui/material';
import { useTableLeadsActions } from '../../../utils/redux/storeHooks/actionsHooks/useTableLeadsActions';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BulkDeleteButton } from '../../../components/bulkDeleteButton/BulkDeleteButton';
import { StyledSearchBar } from '../../../components/styledTextFieldBar/StyledSearchBar';
import { ImportDropDownMenu } from './ImportDropDownMenu';
import { BulkMarkAsReadButton } from '../../../components/bulkMarkAsReadButton/BulkMarkAsReadButton';
import { AnimatePresence } from 'framer-motion';
import { AnimateOpacity } from '../../../components/animateOpacity/AnimateOpacity';
import { FilterButton } from './FilterButton';
import { ExportButton } from './ExportButton';
import _ from 'lodash';
import { SelectedIndicator } from './SelectedIndicator';

export const DashboardLeadsPeopleActions = () => {
  const [searchInput, setSearchInput] = useState<string>('');
  const {
    setLeadToSearch,
    setOrderBy,
    setOrder,
    setPage,
    setRowsPerPage,
    setSelected,
  } = useTableLeadsActions();

  const { isFilterOpen, appliedFilersCount, leadToSearch, isAllSelected, selected } = useTableLeadsData()
  const pathname = useLocation().pathname;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    _.debounce((inputValue) => {
      setLeadToSearch(inputValue);
      setOrderBy('firstName');
      setOrder('ASC');
      setPage(0)
      setRowsPerPage(10)
    }, 500),
    []
  );

  const searchLead = (inputValue: string,) => {
    setSearchInput(inputValue)
    debouncedSearch(inputValue)
  };

  useEffect(() => {
    setSelected([]);
  }, [setSelected, pathname])

  useEffect(() => {
    setSearchInput(leadToSearch)
  }, [leadToSearch])

  return (
    <Box className={scss.actionsContainerMain}>
      <Box className={scss.bulkButtonContainer} sx={{
        width: (selected.length || isAllSelected) ? '100%' : 0,
        paddingLeft: (selected.length || isAllSelected) ? '4px' : 0
      }}>
        {(selected.length || isAllSelected) && <>
          <BulkDeleteButton />
          <BulkMarkAsReadButton />
          <SelectedIndicator />
        </>}

      </Box>
      <AnimatePresence>
        {(!isAllSelected && !selected.length) &&
          <AnimateOpacity>
            <Box className={scss.actionsContainer}>
              <AnimatePresence>
                {!isFilterOpen && !appliedFilersCount &&
                  <AnimateOpacity>
                    <StyledSearchBar inputValue={searchInput} setFunction={searchLead} />
                  </AnimateOpacity>
                }
              </AnimatePresence>
              <FilterButton />
              <ExportButton />
              <ImportDropDownMenu />
            </Box>
          </AnimateOpacity>}

      </AnimatePresence>
    </Box>
  )
}