import React, { useState } from 'react';
import styles from "./ManageUser.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { Avatar, Box, Divider, Stack, Typography } from '@mui/material';
import { MainActionButton } from '../../actionButton/MainActionButton';
import { useAdminPageData } from '../../../utils/redux/storeHooks/dataHooks/useAdminPageData';
import { useAdminPageActions } from '../../../utils/redux/storeHooks/actionsHooks/useAdminPageActions';
import { StyledTextField } from '../../styledTextField/StyledTextField';
import Teams from '../../../types/entities/teams';
import { Roles } from '../../../utils/general/roles';
import { FieldType } from '../../../types/pages/adminPage/filedType';
import { StyledAutocomplete } from '../../styledAutocomplete/StyledAutocomplete';

export const PopUpManageUser = () => {
  const { setManageCurrentUser, updateUser } = useAdminPageActions();
  const { setPopupManageUserOpen } = usePopupActions();
  const { manageCurrentUser, teams, isUserUpdating } = useAdminPageData();
  const [inputErrors, setInputError] = useState([]);
  const [limit, setLimit] = useState<number | string>(manageCurrentUser.slots)
  const [proxy, setProxy] = useState<string>(manageCurrentUser.proxy === 'none' ? '' : manageCurrentUser.proxy)
  const [team, setTeam] = useState<Teams>(teams.find(team => team.teamsUuid === manageCurrentUser.organizationId) || null)
  const [roles, setRoles] = useState<string[] | string>(manageCurrentUser.roles)
  const [email, setEmail] = useState<string>(manageCurrentUser.email)

  const closePopUp = () => {
    setPopupManageUserOpen(false)
    setManageCurrentUser(null)
  }

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const detectError = (inputType: FieldType, value: any) => {
    switch (inputType) {
      case FieldType.limit:
        isNaN(Number(value)) || !value
          ? setInputError([...inputErrors, inputType])
          : setInputError(inputErrors.filter(error => error !== inputType))
        return
      case FieldType.roles:
        !value.length
          ? setInputError([...inputErrors, inputType])
          : setInputError(inputErrors.filter(error => error !== inputType))
        return
      case FieldType.email:
        !isValidEmail(value) && value.length
          ? setInputError([...inputErrors, inputType])
          : setInputError(inputErrors.filter(error => error !== inputType))
        return
    }
    return
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onInputChange = (inputType: FieldType, value: any) => {
    detectError(inputType, value)
    switch (inputType) {
      case FieldType.limit:
        setLimit(value.trim())
        break;
      case FieldType.proxy:
        setProxy(value.trim())
        break;
      case FieldType.team:
        setTeam(value)
        break;
      case FieldType.roles:
        setRoles(value)
        break;
      case FieldType.email:
        setEmail(value.trim())
        break;
    }
  }

  const sendUpdateData = () => {
    updateUser({
      userUuid: manageCurrentUser.uidd,
      limit: Number(limit),
      proxy,
      team,
      roles: [...roles],
      email
    })
  }

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.header__title}>
          Manage user
        </Box>
        <IconButton
          color="default"
          onClick={closePopUp}
          className={styles.header__closeButton}
        >
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>
      <Box className={styles.content}>
        <Box className={styles.profileWrapper}>
          <a
            className={styles.firstLastNameText}
            href={`https://${styles.dashEntityUrn}`}
            target='_blank'
            rel="noreferrer"
          >
            <Stack direction="row">
              <Avatar
                alt=""
                src={manageCurrentUser.profileImg}
                sx={{ width: '40px', height: '40px' }}
              />
            </Stack>
          </a>
          <Box className={styles.userInfo} sx={{ marginLeft: '12px' }}>
            <Box className={styles.firstLastNameText}>

              {manageCurrentUser.firstName} {manageCurrentUser.lastName}
            </Box>
            <Box className={styles.titleText}>
              {manageCurrentUser.title || ''}
            </Box>
          </Box>
        </Box>
        <Divider sx={{ width: '100%' }} />
        <Box className={styles.content__settings}>
          <Box className={styles.content__setting}>
            <Typography className={styles.content__settingTitle}>
              Leads limit:
            </Typography>
            <StyledTextField
              value={limit}
              sx={{ width: '400px' }}
              onChange={(e) => onInputChange(FieldType.limit, e.target.value)}
              error={inputErrors.some(error => error === 'limit')}
              helperText={inputErrors.some(error => error === 'limit') && 'This field need value of only numbers'}
              required
            />
          </Box>

          <Box className={styles.content__setting}>
            <Typography className={styles.content__settingTitle}>
              Proxy:
            </Typography>
            <StyledTextField
              onChange={(e) => onInputChange(FieldType.proxy, e.target.value)}
              value={proxy}
              sx={{ width: '400px' }}
            />
          </Box>

          <Box className={styles.content__setting}>
            <Typography className={styles.content__settingTitle}>
              Team:
            </Typography>
            <StyledAutocomplete
              clearIcon={null}
              disablePortal
              options={teams}
              getOptionLabel={(option: Teams) => `${option.teamName}`}
              renderOption={(props, option: Teams) => (
                <li {...props}>
                  {option.teamName}
                </li>
              )} sx={{ width: '400px' }}
              value={team}
              onChange={(event, newValue: Teams | null) => onInputChange(FieldType.team, newValue)}
              ListboxProps={{ sx: { "& li": { fontFamily: "Kanit", fontWeight: 300 } } }}
              renderInput={(params) => <StyledTextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                }}
              />}
            />
          </Box>

          <Box className={styles.content__setting}>
            <Typography className={styles.content__settingTitle}>
              Roles:
            </Typography>
            <StyledAutocomplete
              clearIcon={null}
              multiple
              disablePortal
              options={Roles}
              sx={{ width: '400px' }}
              value={roles}
              onChange={(event, newValue: string | null) => onInputChange(FieldType.roles, newValue)}
              ListboxProps={{ sx: { "& li": { fontFamily: "Kanit", fontWeight: 300 } } }}
              renderInput={(params) => <StyledTextField
                error={inputErrors.some(error => error === 'roles')}
                helperText={inputErrors.some(error => error === 'roles') && 'select at least one role'}
                {...params}
                InputProps={{
                  ...params.InputProps,
                }}
              />}
            />
          </Box>

          <Box className={styles.content__setting}>
            <Typography className={styles.content__settingTitle}>
              Email:
            </Typography>
            <StyledTextField
              error={inputErrors.some(error => error === 'email')}
              helperText={inputErrors.some(error => error === 'email') && 'Inccorect email'}
              onChange={(e) => onInputChange(FieldType.email, e.target.value)}
              value={email}
              sx={{ width: '400px' }}
            />
          </Box>
        </Box>
      </Box>

      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={closePopUp}
          className={styles.footer__cancel}
        >
          Cancel
        </Button>
        <MainActionButton
          title='Update'
          height={40}
          width={66}
          fontWeight={300}
          isDisabled={!!inputErrors.length || isUserUpdating}
          actionOnClick={sendUpdateData}
          isLoading={isUserUpdating}
        />
      </Box>
    </Box>
  );
};
